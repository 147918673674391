

/*--Author: W3Layouts
	Author URL: http://w3layouts.com
	License: Creative Commons Attribution 3.0 Unported
	License URL: http://creativecommons.org/licenses/by/3.0/
 --*/

 body {
    mEXNin: 0;
    font-family: 'Fira Sans Condensed', sans-serif;
    background: #fff;
}

body a,
.slider-info a {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    text-decoration: none;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    mEXNin: 0;
    font-family: 'Fira Sans Condensed', sans-serif;
}

p {
    mEXNin: 0;
    color: #777;
    font-size: 1em;
    line-height: 1.6;
}

ul {
    mEXNin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

body a:hover {
    text-decoration: none;
}

body a:focus {
    outline: none;
    text-decoration: none;
}

/* nav */

header {
    position: relative;
}

.logo_wthree a {
    color: #fff;
    letter-spacing: 1px;
    border: 1px solid transparent;
    position: absolute;
    top: 15px;
    display: block;
    left: 48%;
    text-transform: capitalize;
    width: auto;
    z-index: 1;
}

.logo_wthree a i {
    font-size: 4em;
}

/* /nav */

.block {
    width: 70px;
    display: block;
    height: 2px;
    background: #dba539;
    background: -moz-linear-gradient(-45deg, #e55d87 0%, #5fc3e4 100%);
    background: -webkit-linear-gradient(-45deg, #dba539 0%, #dc143c 100%);
    background: linear-gradient(135deg, #dba539 0%, #dc143c 100%);
}

/* banner */

.layer {
    background: rgba(25, 98, 106, 0.23);
    background: rgba(0, 0, 0, 0.39);
    padding: 19vw 0 0;
    min-height: 100vh;
}

.agile_banner.bg1 {
    background: url(./images/banner.jpg) center no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-size: cover;
}

.agile_banner.bg2 {
    background: url(./images/bg.jpg) center no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-size: cover;
}

.agile_banner.bg3 {
    background: url(./images/bg.jpg) center no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-size: cover;
}

.agile_banner.bg4 {
    background: url(./images/bg.jpg) center no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-size: cover;
}

.banner_text_wthree h1,
.banner_text_wthree h2 {
    text-transform: uppercase;
    color: rgba(212, 209, 209, 0.59);
    letter-spacing: 3px;
    font-size: 2.5em;
}

ul.list-inline.bnr_list_w3 li a,
a.btn-slide {
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 1.1em;
    background: rgba(0, 0, 0, 0.46);
    padding: 0.5em 2em;
    border: 1px solid transparent;
    font-weight: 300;
}

ul.list-inline.bnr_list_w3 li a:hover,
a.btn-slide:hover {
    -webkit-transition: border 3s ease;
    -moz-transition: border 3s ease;
    -o-transition: border 3s ease;
    -ms-transition: border 3s ease;
    transition: border 3s ease;
    border: 1px solid #fff;
}

.bg-dark {
    background-color: #171209 !important;
}

/* //banner */

/* type writer */

#text {
    font-size: 2.3em;
    color: #edad29;
    letter-spacing: 1px;
}

span.banner_text_w3ls {
    border-right: .05em solid;
    animation: caret 1s steps(1) infinite;
}

@keyframes caret {
    50% {
        border-color: transparent;
    }
}

/* //type writer */

/* inner banner */

.inner-banner-agile {
    background: url(./images/bg.jpg) no-repeat center;
    background-size: cover;
    min-height: 300px;
}

.inner-banner-agile.skillset {
    background: #dc143c url(./images/bg.png) center no-repeat;
    background-size: cover;
}

.inner-banner-agile.banner-port {
    background: #e64c3e;
}

.inner-banner-agile.services {
    background: #19adf2;
}

.inner-banner-agile.contact-bg {
    background: #fbcf4a url(./images/bg.png) center no-repeat;
    background-size: cover;
}

.wthree-inner-sec {
    background: #1d1c1b;
    mEXNin-top: -140px;
    border-radius: 30px;
    padding: 2em;
}

/* .wthree-inner-sec.skills-bg {
    background: #1d1c1b url(./images/skills.jpg) no-repeat center;
    background-size: cover;
} */

.sec-head {
    mEXNin: 1em 0 2em;
}

h1.sec-title-w3 {
    color: rgba(212, 209, 209, 0.59);
    font-size: 3em;
    font-weight: 600;
    letter-spacing: 1.5px;
}

/* //inner banner */

/* portfolio */

.portfolio-categ li {
    color: #333;
    cursor: pointer;
    display: inline-block;
    padding: 7px 15px;
    font-size: 1em;
    mEXNin: 0 0.4em;
    letter-spacing: 1px;
    text-transform: capitalize;
    background: #000;
}

.portfolio-item2 a {
    cursor: zoom-in;
}

.portfolio-categ li a {
    color: #777;
}

.image-block {
    display: block;
    position: relative;
}

.portfolio-area li {
    float: left;
    list-style-type: none;
    overflow: hidden;
    max-width: 261px;
}

.home-portfolio-text {
    mEXNin-top: 10px;
}

.portfolio-categ li.active {
    background: #dc143c;
    color: #fff;
}

.portfolio-categ li.active a {
    color: #fff;
}

/* //portfolio */

/* contact */

.form-wrapper {
    background-color: #fff;
    color: #34495e;
    mEXNin: 10px 0;
    padding: 3em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input:focus {
    outline: none;
}

/* TEXT INPUTS */

.text-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #1d1c1b;
    mEXNin: 0;
    padding: 5px 15px;
    font-family: 'Fira Sans Condensed', sans-serif;
    color: #34495e;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* CHECKBOX INPUTS */

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]:checked+.checkbox-label {
    background-color: #dba539;
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.checkbox-label {
    display: block;
    padding: 30px 0;
    border: 1px solid #d4d4d4;
    color: #34495e;
    cursor: pointer;
}

.text,
.icon {
    display: block;
    padding: 5px 0;
    text-align: center;
}

/* RANGE INPUT */

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #34495e;
    mEXNin-top: -4px;
}

input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #34495e;
    mEXNin-top: -4px;
}

input[type=range]::-ms-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #34495e;
    mEXNin-top: -4px;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

/* TEXTAREA */

.message h2 {
    background-color: #1d1c1b;
    color: #fff;
    font-size: 1em;
    padding: 10px 15px;
}

#t1 {
    width: 100%;
    height: 100px;
    border: 1px solid #1d1c1b;
    mEXNin: 0;
    padding: 15px;
    font-family: 'Fira Sans Condensed', sans-serif;
    color: #34495e;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#t1:focus {
    outline: none;
}

/* SUBMIT BUTTON */

.submit {
    border: 1px solid #1d1c1b;
    color: #000;
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;
    display: inline-block;
    padding: 8px 25px;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.submit:hover {
    background-color: #1d1c1b;
    color: #fff;
    padding: 0.5em 3em;
}

/* //contact */

/* about */

.profile-image {
    border-radius: 1em;
    background-color: #dc143c;
}

.profile-title {
    display: block;
    position: absolute;
    top: 8%;
    left: 34%;
    color: #333;
    z-index: 2;
    font-weight: 700;
    font-size: 4.5em;
    line-height: 1em;
    mEXNin: 0;
}

.profile-title span {
    display: block;
    text-align: right;
    color: #fff;
    mEXNin-left: 1.5em;
}

.profile-info {
    position: absolute;
    top: 30%;
    width: 61%;
    padding: 2rem 2rem;
    right: 0;
    mEXNin: 1rem;
    border-radius: 1em;
    background: #fff;
    /* -webkit-box-shadow: 0px 15px 50px -13px rgba(0,0,0,0.34); */
    -moz-box-shadow: 0px 15px 50px -13px rgba(0, 0, 0, 0.34);
    box-shadow: 0px 15px 50px -13px rgba(0, 0, 0, 0.34);
}

.profile-info:before,
.profile-info:after {
    position: absolute;
    width: 50%;
    height: 20px;
    content: ' ';
    left: 25px;
    bottom: 30px;
    transform: skew(-7deg) rotate(-3deg);
    box-shadow: 0 30px 2px 7px rgba(0, 0, 0, 0.3);
    z-index: -1;
}

.profile-info:after {
    left: auto;
    right: 25px;
    transform: skew(4deg) rotate(3deg);
}

.profile-info .content h3 {
    text-transform: uppercase;
    color: #dc143c;
    letter-spacing: 0.2em;
}

.profile-info .content p {
    font-weight: 400;
    mEXNin: 2rem 0;
    line-height: 1.5;
}

.profile-info .content .link_about a {
    color: #333;
}

.profile-info .content .link_about i {
    color: #dc143c;
    mEXNin-right: 4%;
}

.profile-info .content .link_about a:hover,
.profile-info .content .link_about a:visited {
    text-decoration: none;
    color: inherit;
    transition: color 0.25s;
}

.profile-info .content .link_about a:hover,
.profile-info .content .link_about a:visited:hover {
    color: #dc143c;
    transition: color 0.25s;
}

.profile-info .stats .stat {
    color: #dc143c;
    font-size: 0.95em;
    line-height: 2;
}

.profile-info .stats .stat .number {
    font-size: 2em;
    font-weight: 300;
    font-family: 'Fira Sans Condensed', sans-serif;
    line-height: 100%;
    color: #333;
    vertical-align: top;
    mEXNin-right: 3%;
}

.content img {
    border-radius: 50% !important;
    border-color: #000;
    padding: 5px;
    border-bottom: 5px solid #e9a422;
    border-right: 5px solid #efab2e;
}

/* //about */

/* skills */

.wthree-inner-sec.skills-bg h4 {
    color: #333;
}

/* Table */

#data-table {
    border: none;
    /* Turn off all borders */
    border-top: 1px solid #ccc;
    width: 60%;
}

#data-table th,
#data-table td {
    border: none;
    /* Turn off all borders */
    border-bottom: 1px solid #ccc;
    mEXNin: 0;
    padding: 10px;
    text-align: left;
}

/* Toggle */

.toggles {
    background: #ebebeb;
    color: #545454;
    height: 20px;
    padding: 15px;
}

.toggles p {
    mEXNin: 0;
}

.toggles a {
    background: #222;
    border-radius: 3px;
    color: #fff;
    display: block;
    float: left;
    mEXNin: 0 10px 0 0;
    padding: 0 6px;
    text-decoration: none;
}

.toggles a:hover {
    background: #666;
}

#reset-graph-button {
    float: right;
}

/* Graph */

/* Containers */

#wrapper {
    mEXNin: 25px auto;
    width: 60%;
}

#figure {
    position: relative;
}

#figure ul {
    list-style: none;
    mEXNin: 0;
    padding: 0;
}

.graph {
    height: 283px;
    position: relative;
}

/* Legend */

.legend {
    background: #f0f0f0;
    border-radius: 4px;
    bottom: 0;
    position: absolute;
    text-align: left;
    width: 540px;
}

.legend li {
    display: block;
    float: left;
    height: 20px;
    mEXNin: 0;
    padding: 10px 30px;
    width: 120px;
}

.legend span.icon {
    background-position: 50% 0;
    border-radius: 2px;
    display: block;
    float: left;
    height: 16px;
    mEXNin: 2px 10px 0 0;
    width: 16px;
}

/* X-Axis */

.x-axis {
    bottom: 7px;
    color: #555;
    position: absolute;
    text-align: center;
    width: 100%;
}

.x-axis li {
    float: left;
    mEXNin: 0 15px;
    padding: 5px 0;
    width: 14%;
}

.x-axis li span {
    float: left;
    color: #333;
    background: #fff;
    padding: 0 9px;
    font-weight: 600;
    font-size: 14px;
}

/* Y-Axis */

.y-axis {
    color: #555;
    position: absolute;
    text-align: right;
    width: 100%;
}

.y-axis li {
    border-top: 1px solid #ccc;
    display: block;
    height: 62px;
    width: 100%;
}

.y-axis li span {
    display: block;
    mEXNin: -10px 0 0 -60px;
    padding: 0 10px;
    width: 40px;
    color: #000;
    font-size: 1em;
    font-weight: 600;
}

/* Graph Bars */

.bars {
    height: 249px;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.bar-group {
    float: left;
    height: 100%;
    mEXNin: 0 15px;
    position: relative;
    width: 14%;
}

.bar {
    border-radius: 3px 3px 0 0;
    bottom: 0;
    cursor: pointer;
    height: 0;
    position: absolute;
    text-align: center;
    width: 24px;
}

.bar span {
    background: #fefefe;
    border-radius: 3px;
    left: -8px;
    display: none;
    mEXNin: 0;
    position: relative;
    text-shadow: rgba(255, 255, 255, 0.8) 0 1px 0;
    width: 40px;
    z-index: 20;
    -webkit-box-shadow: rgba(0, 0, 0, 0.6) 0 1px 4px;
    box-shadow: rgba(0, 0, 0, 0.6) 0 1px 4px;
}

.bar:hover span {
    display: block;
    mEXNin-top: -25px;
}

#data-table.js {
    display: none;
}

.bar span {
    background: #fefefe;
}

.fig0 {
    background: #edad29;
    box-shadow: 5px -2px 1px rgba(17, 13, 7, 0.63);
}

/* //skills */

.wthree-inner-sec.services-bg {
    background: rgba(255, 255, 255, 0.12);
}

.services-box {
    mEXNin-bottom: 20px;
    border-bottom: 1px dashed #dfe0e1;
    padding: 20px 0;
    transition: all 1s;
    -moz-transition: all 1s;
    /* Firefox 4 */
    -webkit-transition: all 1s;
    /* Safari and Chrome */
    -o-transition: all 1s;
    /* Opera */
}

.services-box:hover {
    background: #f2f2f2;
    transition: all 1s;
    -moz-transition: all 1s;
    /* Firefox 4 */
    -webkit-transition: all 1s;
    /* Safari and Chrome */
    -o-transition: all 1s;
    /* Opera */
}

.services-box:hover i {
    background: transparent;
    border-radius: 100px;
    color: #dc143c;
}

.icon i {
    width: 70px;
    height: 70px;
    color: #dc143c;
    border-radius: 50%;
    line-height: 70px;
    text-align: center;
    font-size: 26px;
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
}

.service-content {
    mEXNin-left: 1em;
}

.service-content h4,
.service-content h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
    mEXNin-bottom: 0.5em;
}

.service-txt h5 {
    text-transform: capitalize;
    font-size: 2.5em;
    color: #000;
    letter-spacing: 1px;
    font-weight: 300;
    line-height: 1.5;
}

.service-txt h5 span {
    background: #19adf2;
    font-weight: 600;
    color: #fff;
    padding: 0 4px;
}

/* //services */

.cpy-right {
    background: #110d07;
}

.cpy-right p,
.cpy-right a {
    color: #edad29;
    font-size: 14px;
    letter-spacing: 1px;
}

/* -- Responsive code -- */
@media screen and (max-width: 1366px) {
    .profile-title {
        font-size: 4em;
    }
}

@media screen and (max-width: 1280px) {
    #menu ul {
        mEXNin-top: 100px;
    }
}

@media screen and (max-width: 1080px) {
    .profile-title {
        font-size: 3.8em;
        top: 12%;
    }
}

@media screen and (max-width: 1050px) {}

@media screen and (max-width: 1024px) {
    h1.sec-title-w3 {
        color: rgba(212, 209, 209, 0.59);
        font-size: 2.5em;
    }
    .service-txt h5 {
        font-size: 2em;
        letter-spacing: 0px;
    }
}

@media screen and (max-width: 991px) {
    .inner-banner-agile {
        min-height: 260px;
    }
    #wrapper {
        width: 80%;
    }
    .profile-info {
        width: 85%;
    }
    .service-txt h5 {
        font-size: 1.8em;
    }
    .portfolio-categ li {
        padding: 7px 13px;
        mEXNin: 0 0.4em 0 0;
    }
    .portfolio-area li {
        max-width: 208px;
    }
}

@media screen and (max-width: 900px) {
    h1.sec-title-w3 {
        letter-spacing: 1px;
    }
    .checkbox-label {
        padding: 15px 0;
    }
    .icon i {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
    .layer {
        padding: 26vw 0 0;
    }
    .agile_banner.bg1 {
        background-position: right;
    }
}

@media screen and (max-width: 800px) {
    .profile-title {
        font-size: 3.2em;
        top: 12%;
    }
    .banner_text_wthree h1,
    .banner_text_wthree h2 {
        letter-spacing: 1px;
        font-size: 2.2em;
    }
}

@media screen and (max-width: 768px) {
    #text {
        font-size: 2em;
    }
}

@media screen and (max-width: 736px) {
    h1.sec-title-w3 {
        font-size: 2.2em;
    }
    .bar-group,
    .x-axis li {
        width: 10%;
    }
    .profile-info {
        top: 28%;
        width: 100%;
        right: -15px;
    }
    .profile-title {
        font-size: 3em;
        top: 6%;
    }
    .portfolio-categ li {
        mEXNin: 0.4em;
        font-size: 0.9em;
    }
    .portfolio-area li {
        max-width: 148px;
    }
    .layer {
        padding: 34vw 0 0;
    }
}

@media screen and (max-width: 667px) {
    .inner-banner-agile {
        min-height: 220px;
    }
    .wthree-inner-sec {
        mEXNin-top: -90px;
        padding: 1.5em;
    }
    .service-txt h5 {
        font-size: 1.6em;
        mEXNin-top: 2em;
    }
}

@media screen and (max-width: 640px) {
    .profile-title {
        font-size: 2.8em;
    }
    .layer {
        padding: 40vw 0 0;
    }
}

@media screen and (max-width: 568px) {
    h1.sec-title-w3 {
        font-size: 2em;
    }
    .form-wrapper {
        padding: 2em;
    }
    .about-bot-w3l {
        padding-bottom: 3em;
    }
    .profile-info {
        top: 20%;
    }
    .layer {
        padding: 43vw 3vw 0;
    }
}

@media screen and (max-width: 480px) {
    .wthree-inner-sec {
        mEXNin-top: -90px;
        padding: 1em;
    }
    .bar-group {
        mEXNin: 0 0 0 30px;
    }
    .y-axis li span {
        mEXNin: -10px 0 0 -33px;
    }
    .profile-title {
        font-size: 2.5em;
    }
    .profile-info {
        top: 22%;
    }
    .wthree-inner-sec.about-bot-w3ls {
        padding-bottom: 9em;
    }
    .portfolio-area li {
        max-width: 133px;
    }
    .portfolio-categ li {
        mEXNin: 0.4em 0.4em 0.4em 0;
    }
    #text {
        font-size: 1.8em;
        height: 90px;
    }
    .layer {
        padding: 50vw 3vw 0;
    }
}

@media screen and (max-width: 414px) {
    ul.list-inline.bnr_list_w3 li a,
    a.btn-slide {
        padding: 0.5em 1em;
    }
    .bar-group,
    .x-axis li {
        width: 7%;
    }
    .row.pro-contact {
        display: block;
    }
    .wthree-inner-sec.about-bot-w3ls {
        padding-bottom: 13em;
    }
    .profile-info .stats .stat .number {
        font-size: 1.8em;
    }
    .service-txt h5 {
        font-size: 1.4em;
    }
    .portfolio-area li {
        max-width: 167px;
    }
    .layer {
        padding: 58vw 3vw 0;
    }
}

@media screen and (max-width: 384px) {
    .form-wrapper {
        padding: 1em;
    }
    .bar-group,
    .x-axis li {
        width: 5%;
    }
    .profile-info.row {
        display: block;
    }
    .link_about {
        mEXNin-top: 1em;
    }
    .profile-info {
        padding: 2rem 1rem;
    }
    .wthree-inner-sec.about-bot-w3ls {
        padding-bottom: 17em;
    }
    .profile-title {
        font-size: 2.2em;
    }
    .portfolio-categ li {
        mEXNin: 0 0.2em 0.2em 0;
        font-size: 0.85em;
    }
    .portfolio-area li {
        max-width: 304px;
    }
    .banner_text_wthree h1, .banner_text_wthree h2 {
        font-size: 1.85em;
    }
}

@media screen and (max-width: 375px) {
    .portfolio-categ li {
        letter-spacing: 0.5px;
    }
    #text {
        font-size: 1.6em;
    }
    .logo_wthree a i {
        font-size: 3.5em;
    }
}

@media screen and (max-width: 320px) {
    .banner_text_wthree h1, .banner_text_wthree h2 {
        font-size: 1.6em;
    }
    #text {
        font-size: 1.4em;
    }
    #wrapper {
        width: 90%;
    }
    .profile-title {
        font-size: 1.8em;
        top: 3%;
        left: 27%;
    }
    .wthree-inner-sec.about-bot-w3ls {
        padding-bottom: 24em;
    }
    .cpy-right {
        padding: 1rem;
    }
    .service-content h4,
    .service-content h2 {
        font-size: 18px;
    }
    .portfolio-categ li {
        letter-spacing: 0px;
    }
    ul.list-inline.bnr_list_w3 li a, a.btn-slide {
        padding: 0.5em 0.4em;
        font-size: 1em;
    }
    .layer {
        padding: 75vw 3vw 0;
    }
}

/* -- //Responsive code -- */


.content {
    position: absolute;
    overflow: hidden;
    width: 100%;
    mEXNin: 5em auto 0;
  }
  .content article {
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .content article p {
    border-bottom: 1px dotted #444;
  }
  .content article label {
    display: inline-block;
    mEXNin-bottom: 3em;
  }
  .content article label input {
    display: inline-block;
  }
  
  .container {
    z-index: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
  }
  
  /*
  *
  *
  START // CHART'S RULES
   -> "if you're picking code, don't forget the variables :)"
  */
  .chart {
    font-size: 1.8em;
    display: flex;
    flex-direction: row;
    height: 12em;
    justify-content: center;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
  }
  
  .bar {
    font-size: 1em;
    position: relative;
    width: 2em;
    padding: 1em;
    transition: -webkit-transform 2s linear;
    transition: transform 2s linear;
    transition: transform 2s linear, -webkit-transform 2s linear;
    -webkit-transform: rotateX(-30deg) rotateY(-135deg);
            transform: rotateX(-30deg) rotateY(-135deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  .bar.red .face.floor {
    background-color: rgba(185, 0, 110, 0.2);
  }
  .bar.red .face > .growing-bar {
    background-color: rgba(236, 0, 140, 0.8);
  }
  .bar.red .face > .growing-bar:before {
    color: #530031;
    border-top-color: #b9006e;
  }
  .bar.cyan .face.floor {
    background-color: rgba(39, 187, 241, 0.2);
  }
  .bar.cyan .face > .growing-bar {
    background-color: rgba(87, 202, 244, 0.8);
  }
  .bar.cyan .face > .growing-bar:before {
    color: #0b7da7;
    border-top-color: #27bbf1;
  }
  .bar.navy .face.floor {
    background-color: rgba(6, 36, 58, 0.2);
  }
  .bar.navy .face > .growing-bar {
    background-color: rgba(10, 64, 105, 0.8);
  }
  .bar.navy .face > .growing-bar:before {
    color: black;
    border-top-color: #06243a;
  }
  .bar.lightGray .face.floor {
    background-color: rgba(119, 119, 119, 0.2);
  }
  .bar.lightGray .face > .growing-bar {
    background-color: rgba(145, 145, 145, 0.8);
  }
  .bar.lightGray .face > .growing-bar:before {
    color: #444444;
    border-top-color: #777777;
  }
  .bar.yellow .face.floor {
    background-color: rgba(194, 157, 11, 0.2);
  }
  .bar.yellow .face > .growing-bar {
    background-color: rgba(241, 196, 15, 0.8);
  }
  .bar.yellow .face > .growing-bar:before {
    color: #614f06;
    border-top-color: #c29d0b;
  }
  .bar.lime .face.floor {
    background-color: rgba(88, 150, 0, 0.2);
  }
  .bar.lime .face > .growing-bar {
    background-color: rgba(118, 201, 0, 0.8);
  }
  .bar.lime .face > .growing-bar:before {
    color: #1c3000;
    border-top-color: #589600;
  }
  .bar.gray .face.floor {
    background-color: rgba(43, 43, 43, 0.2);
  }
  .bar.gray .face > .growing-bar {
    background-color: rgba(68, 68, 68, 0.8);
  }
  .bar.gray .face > .growing-bar:before {
    color: black;
    border-top-color: #2b2b2b;
  }
  .bar .face {
    position: relative;
    bottom: 0;
    width: 2em;
    height: 2em;
    background-position: center center;
  }
  .bar .side-0,
  .bar .side-1 {
    overflow: hidden;
    height: 10em;
  }
  .bar .side-0 {
    -webkit-transform: rotateY(90deg) translateZ(1em);
            transform: rotateY(90deg) translateZ(1em);
    background-color: rgba(241, 241, 241, 0.8);
  }
  .bar .side-0 .growing-bar {
    background-color: #57caf4;
  }
  .bar .side-1 {
    -webkit-transform: rotateY(180deg) translateY(-10em) translateZ(1em);
            transform: rotateY(180deg) translateY(-10em) translateZ(1em);
    background-color: rgba(216, 216, 216, 0.8);
  }
  .bar .side-1 .growing-bar {
    background-color: #10b3ef;
  }
  .bar .top {
    -webkit-transform: rotateX(90deg) translateZ(21em);
            transform: rotateX(90deg) translateZ(21em);
    text-align: center;
    background-color: rgba(254, 254, 254, 0.8);
  }
  .bar .floor {
    -webkit-transform: rotateX(-90deg) translateY(0em) translateZ(-13em) rotate(180deg);
            transform: rotateX(-90deg) translateY(0em) translateZ(-13em) rotate(180deg);
    text-align: center;
    background-color: transparent;
    box-shadow: 0 0 0.6em rgba(0, 0, 0, 0.3), 0.6em -1em 3em rgba(0, 0, 0, 0.3), 1em 1em 10em rgba(254, 254, 254, 0.8);
  }
  .bar .growing-bar {
    display: inline-block;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
  }
  .bar .growing-bar:before {
    font-family: 'Open Sans', sans-serif;
    font-size: .6em;
    font-weight: 700;
    display: inline-block;
    box-sizing: content-box;
    padding: .8em;
    text-align: center;
    opacity: 1;
    color: #0a4069;
    border-top: 2px dotted #0a4069;
  }
  
  .small-font-size {
    font-size: 1em;
  }
  
  .reglar-font-size {
    font-size: 1.5em;
  }
  
  .big-font-size {
    font-size: 1.8em;
  }
  
  .chart .bar-100 .face.side-0 .growing-bar,
  .chart .bar-100 .face.side-1 .growing-bar {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: .8;
  }
  .chart .bar-100 .face.side-0 .growing-bar:before,
  .chart .bar-100 .face.side-1 .growing-bar:before {
    content: "100";
  }
  
  .chart .bar-99 .face.side-0 .growing-bar,
  .chart .bar-99 .face.side-1 .growing-bar {
    -webkit-transform: translateY(1%);
            transform: translateY(1%);
    opacity: .8;
  }
  .chart .bar-99 .face.side-0 .growing-bar:before,
  .chart .bar-99 .face.side-1 .growing-bar:before {
    content: "99";
  }
  
  .chart .bar-98 .face.side-0 .growing-bar,
  .chart .bar-98 .face.side-1 .growing-bar {
    -webkit-transform: translateY(2%);
            transform: translateY(2%);
    opacity: .8;
  }
  .chart .bar-98 .face.side-0 .growing-bar:before,
  .chart .bar-98 .face.side-1 .growing-bar:before {
    content: "98";
  }
  
  .chart .bar-97 .face.side-0 .growing-bar,
  .chart .bar-97 .face.side-1 .growing-bar {
    -webkit-transform: translateY(3%);
            transform: translateY(3%);
    opacity: .8;
  }
  .chart .bar-97 .face.side-0 .growing-bar:before,
  .chart .bar-97 .face.side-1 .growing-bar:before {
    content: "97";
  }
  
  .chart .bar-96 .face.side-0 .growing-bar,
  .chart .bar-96 .face.side-1 .growing-bar {
    -webkit-transform: translateY(4%);
            transform: translateY(4%);
    opacity: .8;
  }
  .chart .bar-96 .face.side-0 .growing-bar:before,
  .chart .bar-96 .face.side-1 .growing-bar:before {
    content: "96";
  }
  
  .chart .bar-95 .face.side-0 .growing-bar,
  .chart .bar-95 .face.side-1 .growing-bar {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
    opacity: .8;
  }
  .chart .bar-95 .face.side-0 .growing-bar:before,
  .chart .bar-95 .face.side-1 .growing-bar:before {
    content: "95";
  }
  
  .chart .bar-94 .face.side-0 .growing-bar,
  .chart .bar-94 .face.side-1 .growing-bar {
    -webkit-transform: translateY(6%);
            transform: translateY(6%);
    opacity: .8;
  }
  .chart .bar-94 .face.side-0 .growing-bar:before,
  .chart .bar-94 .face.side-1 .growing-bar:before {
    content: "94";
  }
  
  .chart .bar-93 .face.side-0 .growing-bar,
  .chart .bar-93 .face.side-1 .growing-bar {
    -webkit-transform: translateY(7%);
            transform: translateY(7%);
    opacity: .8;
  }
  .chart .bar-93 .face.side-0 .growing-bar:before,
  .chart .bar-93 .face.side-1 .growing-bar:before {
    content: "93";
  }
  
  .chart .bar-92 .face.side-0 .growing-bar,
  .chart .bar-92 .face.side-1 .growing-bar {
    -webkit-transform: translateY(8%);
            transform: translateY(8%);
    opacity: .8;
  }
  .chart .bar-92 .face.side-0 .growing-bar:before,
  .chart .bar-92 .face.side-1 .growing-bar:before {
    content: "92";
  }
  
  .chart .bar-91 .face.side-0 .growing-bar,
  .chart .bar-91 .face.side-1 .growing-bar {
    -webkit-transform: translateY(9%);
            transform: translateY(9%);
    opacity: .8;
  }
  .chart .bar-91 .face.side-0 .growing-bar:before,
  .chart .bar-91 .face.side-1 .growing-bar:before {
    content: "91";
  }
  
  .chart .bar-90 .face.side-0 .growing-bar,
  .chart .bar-90 .face.side-1 .growing-bar {
    -webkit-transform: translateY(10%);
            transform: translateY(10%);
    opacity: .8;
  }
  .chart .bar-90 .face.side-0 .growing-bar:before,
  .chart .bar-90 .face.side-1 .growing-bar:before {
    content: "90";
  }
  
  .chart .bar-89 .face.side-0 .growing-bar,
  .chart .bar-89 .face.side-1 .growing-bar {
    -webkit-transform: translateY(11%);
            transform: translateY(11%);
    opacity: .8;
  }
  .chart .bar-89 .face.side-0 .growing-bar:before,
  .chart .bar-89 .face.side-1 .growing-bar:before {
    content: "89";
  }
  
  .chart .bar-88 .face.side-0 .growing-bar,
  .chart .bar-88 .face.side-1 .growing-bar {
    -webkit-transform: translateY(12%);
            transform: translateY(12%);
    opacity: .8;
  }
  .chart .bar-88 .face.side-0 .growing-bar:before,
  .chart .bar-88 .face.side-1 .growing-bar:before {
    content: "88";
  }
  
  .chart .bar-87 .face.side-0 .growing-bar,
  .chart .bar-87 .face.side-1 .growing-bar {
    -webkit-transform: translateY(13%);
            transform: translateY(13%);
    opacity: .8;
  }
  .chart .bar-87 .face.side-0 .growing-bar:before,
  .chart .bar-87 .face.side-1 .growing-bar:before {
    content: "87";
  }
  
  .chart .bar-86 .face.side-0 .growing-bar,
  .chart .bar-86 .face.side-1 .growing-bar {
    -webkit-transform: translateY(14%);
            transform: translateY(14%);
    opacity: .8;
  }
  .chart .bar-86 .face.side-0 .growing-bar:before,
  .chart .bar-86 .face.side-1 .growing-bar:before {
    content: "86";
  }
  
  .chart .bar-85 .face.side-0 .growing-bar, input[name='status']:checked + .content article .chart .bar.bar-3 .face.side-0 .growing-bar,
  .chart .bar-85 .face.side-1 .growing-bar,
  input[name='status']:checked + .content article .chart .bar.bar-3 .face.side-1 .growing-bar {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
    opacity: .8;
  }
  .chart .bar-85 .face.side-0 .growing-bar:before, input[name='status']:checked + .content article .chart .bar.bar-3 .face.side-0 .growing-bar:before,
  .chart .bar-85 .face.side-1 .growing-bar:before,
  input[name='status']:checked + .content article .chart .bar.bar-3 .face.side-1 .growing-bar:before {
    content: "85";
  }
  
  .chart .bar-84 .face.side-0 .growing-bar,
  .chart .bar-84 .face.side-1 .growing-bar {
    -webkit-transform: translateY(16%);
            transform: translateY(16%);
    opacity: .8;
  }
  .chart .bar-84 .face.side-0 .growing-bar:before,
  .chart .bar-84 .face.side-1 .growing-bar:before {
    content: "84";
  }
  
  .chart .bar-83 .face.side-0 .growing-bar,
  .chart .bar-83 .face.side-1 .growing-bar {
    -webkit-transform: translateY(17%);
            transform: translateY(17%);
    opacity: .8;
  }
  .chart .bar-83 .face.side-0 .growing-bar:before,
  .chart .bar-83 .face.side-1 .growing-bar:before {
    content: "83";
  }
  
  .chart .bar-82 .face.side-0 .growing-bar,
  .chart .bar-82 .face.side-1 .growing-bar {
    -webkit-transform: translateY(18%);
            transform: translateY(18%);
    opacity: .8;
  }
  .chart .bar-82 .face.side-0 .growing-bar:before,
  .chart .bar-82 .face.side-1 .growing-bar:before {
    content: "82";
  }
  
  .chart .bar-81 .face.side-0 .growing-bar,
  .chart .bar-81 .face.side-1 .growing-bar {
    -webkit-transform: translateY(19%);
            transform: translateY(19%);
    opacity: .8;
  }
  .chart .bar-81 .face.side-0 .growing-bar:before,
  .chart .bar-81 .face.side-1 .growing-bar:before {
    content: "81";
  }
  
  .chart .bar-80 .face.side-0 .growing-bar,
  .chart .bar-80 .face.side-1 .growing-bar {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
    opacity: .8;
  }
  .chart .bar-80 .face.side-0 .growing-bar:before,
  .chart .bar-80 .face.side-1 .growing-bar:before {
    content: "80";
  }
  
  .chart .bar-79 .face.side-0 .growing-bar,
  .chart .bar-79 .face.side-1 .growing-bar {
    -webkit-transform: translateY(21%);
            transform: translateY(21%);
    opacity: .8;
  }
  .chart .bar-79 .face.side-0 .growing-bar:before,
  .chart .bar-79 .face.side-1 .growing-bar:before {
    content: "79";
  }
  
  .chart .bar-78 .face.side-0 .growing-bar,
  .chart .bar-78 .face.side-1 .growing-bar {
    -webkit-transform: translateY(22%);
            transform: translateY(22%);
    opacity: .8;
  }
  .chart .bar-78 .face.side-0 .growing-bar:before,
  .chart .bar-78 .face.side-1 .growing-bar:before {
    content: "78";
  }
  
  .chart .bar-77 .face.side-0 .growing-bar,
  .chart .bar-77 .face.side-1 .growing-bar {
    -webkit-transform: translateY(23%);
            transform: translateY(23%);
    opacity: .8;
  }
  .chart .bar-77 .face.side-0 .growing-bar:before,
  .chart .bar-77 .face.side-1 .growing-bar:before {
    content: "77";
  }
  
  .chart .bar-76 .face.side-0 .growing-bar,
  .chart .bar-76 .face.side-1 .growing-bar {
    -webkit-transform: translateY(24%);
            transform: translateY(24%);
    opacity: .8;
  }
  .chart .bar-76 .face.side-0 .growing-bar:before,
  .chart .bar-76 .face.side-1 .growing-bar:before {
    content: "76";
  }
  
  .chart .bar-75 .face.side-0 .growing-bar, input[name='status']:checked + .content article .chart .bar.bar-0 .face.side-0 .growing-bar,
  .chart .bar-75 .face.side-1 .growing-bar,
  input[name='status']:checked + .content article .chart .bar.bar-0 .face.side-1 .growing-bar {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
    opacity: .8;
  }
  .chart .bar-75 .face.side-0 .growing-bar:before, input[name='status']:checked + .content article .chart .bar.bar-0 .face.side-0 .growing-bar:before,
  .chart .bar-75 .face.side-1 .growing-bar:before,
  input[name='status']:checked + .content article .chart .bar.bar-0 .face.side-1 .growing-bar:before {
    content: "75";
  }
  
  .chart .bar-74 .face.side-0 .growing-bar,
  .chart .bar-74 .face.side-1 .growing-bar {
    -webkit-transform: translateY(26%);
            transform: translateY(26%);
    opacity: .8;
  }
  .chart .bar-74 .face.side-0 .growing-bar:before,
  .chart .bar-74 .face.side-1 .growing-bar:before {
    content: "74";
  }
  
  .chart .bar-73 .face.side-0 .growing-bar,
  .chart .bar-73 .face.side-1 .growing-bar {
    -webkit-transform: translateY(27%);
            transform: translateY(27%);
    opacity: .8;
  }
  .chart .bar-73 .face.side-0 .growing-bar:before,
  .chart .bar-73 .face.side-1 .growing-bar:before {
    content: "73";
  }
  
  .chart .bar-72 .face.side-0 .growing-bar,
  .chart .bar-72 .face.side-1 .growing-bar {
    -webkit-transform: translateY(28%);
            transform: translateY(28%);
    opacity: .8;
  }
  .chart .bar-72 .face.side-0 .growing-bar:before,
  .chart .bar-72 .face.side-1 .growing-bar:before {
    content: "72";
  }
  
  .chart .bar-71 .face.side-0 .growing-bar,
  .chart .bar-71 .face.side-1 .growing-bar {
    -webkit-transform: translateY(29%);
            transform: translateY(29%);
    opacity: .8;
  }
  .chart .bar-71 .face.side-0 .growing-bar:before,
  .chart .bar-71 .face.side-1 .growing-bar:before {
    content: "71";
  }
  
  .chart .bar-70 .face.side-0 .growing-bar,
  .chart .bar-70 .face.side-1 .growing-bar {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
    opacity: .8;
  }
  .chart .bar-70 .face.side-0 .growing-bar:before,
  .chart .bar-70 .face.side-1 .growing-bar:before {
    content: "70";
  }
  
  .chart .bar-69 .face.side-0 .growing-bar,
  .chart .bar-69 .face.side-1 .growing-bar {
    -webkit-transform: translateY(31%);
            transform: translateY(31%);
    opacity: .8;
  }
  .chart .bar-69 .face.side-0 .growing-bar:before,
  .chart .bar-69 .face.side-1 .growing-bar:before {
    content: "69";
  }
  
  .chart .bar-68 .face.side-0 .growing-bar,
  .chart .bar-68 .face.side-1 .growing-bar {
    -webkit-transform: translateY(32%);
            transform: translateY(32%);
    opacity: .8;
  }
  .chart .bar-68 .face.side-0 .growing-bar:before,
  .chart .bar-68 .face.side-1 .growing-bar:before {
    content: "68";
  }
  
  .chart .bar-67 .face.side-0 .growing-bar,
  .chart .bar-67 .face.side-1 .growing-bar {
    -webkit-transform: translateY(33%);
            transform: translateY(33%);
    opacity: .8;
  }
  .chart .bar-67 .face.side-0 .growing-bar:before,
  .chart .bar-67 .face.side-1 .growing-bar:before {
    content: "67";
  }
  
  .chart .bar-66 .face.side-0 .growing-bar,
  .chart .bar-66 .face.side-1 .growing-bar {
    -webkit-transform: translateY(34%);
            transform: translateY(34%);
    opacity: .8;
  }
  .chart .bar-66 .face.side-0 .growing-bar:before,
  .chart .bar-66 .face.side-1 .growing-bar:before {
    content: "66";
  }
  
  .chart .bar-65 .face.side-0 .growing-bar,
  .chart .bar-65 .face.side-1 .growing-bar {
    -webkit-transform: translateY(35%);
            transform: translateY(35%);
    opacity: .8;
  }
  .chart .bar-65 .face.side-0 .growing-bar:before,
  .chart .bar-65 .face.side-1 .growing-bar:before {
    content: "65";
  }
  
  .chart .bar-64 .face.side-0 .growing-bar,
  .chart .bar-64 .face.side-1 .growing-bar {
    -webkit-transform: translateY(36%);
            transform: translateY(36%);
    opacity: .8;
  }
  .chart .bar-64 .face.side-0 .growing-bar:before,
  .chart .bar-64 .face.side-1 .growing-bar:before {
    content: "64";
  }
  
  .chart .bar-63 .face.side-0 .growing-bar,
  .chart .bar-63 .face.side-1 .growing-bar {
    -webkit-transform: translateY(37%);
            transform: translateY(37%);
    opacity: .8;
  }
  .chart .bar-63 .face.side-0 .growing-bar:before,
  .chart .bar-63 .face.side-1 .growing-bar:before {
    content: "63";
  }
  
  .chart .bar-62 .face.side-0 .growing-bar,
  .chart .bar-62 .face.side-1 .growing-bar {
    -webkit-transform: translateY(38%);
            transform: translateY(38%);
    opacity: .8;
  }
  .chart .bar-62 .face.side-0 .growing-bar:before,
  .chart .bar-62 .face.side-1 .growing-bar:before {
    content: "62";
  }
  
  .chart .bar-61 .face.side-0 .growing-bar,
  .chart .bar-61 .face.side-1 .growing-bar {
    -webkit-transform: translateY(39%);
            transform: translateY(39%);
    opacity: .8;
  }
  .chart .bar-61 .face.side-0 .growing-bar:before,
  .chart .bar-61 .face.side-1 .growing-bar:before {
    content: "61";
  }
  
  .chart .bar-60 .face.side-0 .growing-bar,
  .chart .bar-60 .face.side-1 .growing-bar {
    -webkit-transform: translateY(40%);
            transform: translateY(40%);
    opacity: .8;
  }
  .chart .bar-60 .face.side-0 .growing-bar:before,
  .chart .bar-60 .face.side-1 .growing-bar:before {
    content: "60";
  }
  
  .chart .bar-59 .face.side-0 .growing-bar,
  .chart .bar-59 .face.side-1 .growing-bar {
    -webkit-transform: translateY(41%);
            transform: translateY(41%);
    opacity: .8;
  }
  .chart .bar-59 .face.side-0 .growing-bar:before,
  .chart .bar-59 .face.side-1 .growing-bar:before {
    content: "59";
  }
  
  .chart .bar-58 .face.side-0 .growing-bar,
  .chart .bar-58 .face.side-1 .growing-bar {
    -webkit-transform: translateY(42%);
            transform: translateY(42%);
    opacity: .8;
  }
  .chart .bar-58 .face.side-0 .growing-bar:before,
  .chart .bar-58 .face.side-1 .growing-bar:before {
    content: "58";
  }
  
  .chart .bar-57 .face.side-0 .growing-bar,
  .chart .bar-57 .face.side-1 .growing-bar {
    -webkit-transform: translateY(43%);
            transform: translateY(43%);
    opacity: .8;
  }
  .chart .bar-57 .face.side-0 .growing-bar:before,
  .chart .bar-57 .face.side-1 .growing-bar:before {
    content: "57";
  }
  
  .chart .bar-56 .face.side-0 .growing-bar,
  .chart .bar-56 .face.side-1 .growing-bar {
    -webkit-transform: translateY(44%);
            transform: translateY(44%);
    opacity: .8;
  }
  .chart .bar-56 .face.side-0 .growing-bar:before,
  .chart .bar-56 .face.side-1 .growing-bar:before {
    content: "56";
  }
  
  .chart .bar-55 .face.side-0 .growing-bar, input[name='status']:checked + .content article .chart .bar.bar-1-1 .face.side-0 .growing-bar,
  .chart .bar-55 .face.side-1 .growing-bar,
  input[name='status']:checked + .content article .chart .bar.bar-1-1 .face.side-1 .growing-bar {
    -webkit-transform: translateY(45%);
            transform: translateY(45%);
    opacity: .8;
  }
  .chart .bar-55 .face.side-0 .growing-bar:before, input[name='status']:checked + .content article .chart .bar.bar-1-1 .face.side-0 .growing-bar:before,
  .chart .bar-55 .face.side-1 .growing-bar:before,
  input[name='status']:checked + .content article .chart .bar.bar-1-1 .face.side-1 .growing-bar:before {
    content: "55";
  }
  
  .chart .bar-54 .face.side-0 .growing-bar,
  .chart .bar-54 .face.side-1 .growing-bar {
    -webkit-transform: translateY(46%);
            transform: translateY(46%);
    opacity: .8;
  }
  .chart .bar-54 .face.side-0 .growing-bar:before,
  .chart .bar-54 .face.side-1 .growing-bar:before {
    content: "54";
  }
  
  .chart .bar-53 .face.side-0 .growing-bar,
  .chart .bar-53 .face.side-1 .growing-bar {
    -webkit-transform: translateY(47%);
            transform: translateY(47%);
    opacity: .8;
  }
  .chart .bar-53 .face.side-0 .growing-bar:before,
  .chart .bar-53 .face.side-1 .growing-bar:before {
    content: "53";
  }
  
  .chart .bar-52 .face.side-0 .growing-bar,
  .chart .bar-52 .face.side-1 .growing-bar {
    -webkit-transform: translateY(48%);
            transform: translateY(48%);
    opacity: .8;
  }
  .chart .bar-52 .face.side-0 .growing-bar:before,
  .chart .bar-52 .face.side-1 .growing-bar:before {
    content: "52";
  }
  
  .chart .bar-51 .face.side-0 .growing-bar,
  .chart .bar-51 .face.side-1 .growing-bar {
    -webkit-transform: translateY(49%);
            transform: translateY(49%);
    opacity: .8;
  }
  .chart .bar-51 .face.side-0 .growing-bar:before,
  .chart .bar-51 .face.side-1 .growing-bar:before {
    content: "51";
  }
  
  .chart .bar-50 .face.side-0 .growing-bar,
  .chart .bar-50 .face.side-1 .growing-bar {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    opacity: .8;
  }
  .chart .bar-50 .face.side-0 .growing-bar:before,
  .chart .bar-50 .face.side-1 .growing-bar:before {
    content: "50";
  }
  
  .chart .bar-49 .face.side-0 .growing-bar,
  .chart .bar-49 .face.side-1 .growing-bar {
    -webkit-transform: translateY(51%);
            transform: translateY(51%);
    opacity: .8;
  }
  .chart .bar-49 .face.side-0 .growing-bar:before,
  .chart .bar-49 .face.side-1 .growing-bar:before {
    content: "49";
  }
  
  .chart .bar-48 .face.side-0 .growing-bar,
  .chart .bar-48 .face.side-1 .growing-bar {
    -webkit-transform: translateY(52%);
            transform: translateY(52%);
    opacity: .8;
  }
  .chart .bar-48 .face.side-0 .growing-bar:before,
  .chart .bar-48 .face.side-1 .growing-bar:before {
    content: "48";
  }
  
  .chart .bar-47 .face.side-0 .growing-bar,
  .chart .bar-47 .face.side-1 .growing-bar {
    -webkit-transform: translateY(53%);
            transform: translateY(53%);
    opacity: .8;
  }
  .chart .bar-47 .face.side-0 .growing-bar:before,
  .chart .bar-47 .face.side-1 .growing-bar:before {
    content: "47";
  }
  
  .chart .bar-46 .face.side-0 .growing-bar,
  .chart .bar-46 .face.side-1 .growing-bar {
    -webkit-transform: translateY(54%);
            transform: translateY(54%);
    opacity: .8;
  }
  .chart .bar-46 .face.side-0 .growing-bar:before,
  .chart .bar-46 .face.side-1 .growing-bar:before {
    content: "46";
  }
  
  .chart .bar-45 .face.side-0 .growing-bar, input[name='status']:checked + .content article .chart .bar.bar-2 .face.side-0 .growing-bar,
  .chart .bar-45 .face.side-1 .growing-bar,
  input[name='status']:checked + .content article .chart .bar.bar-2 .face.side-1 .growing-bar {
    -webkit-transform: translateY(55%);
            transform: translateY(55%);
    opacity: .8;
  }
  .chart .bar-45 .face.side-0 .growing-bar:before, input[name='status']:checked + .content article .chart .bar.bar-2 .face.side-0 .growing-bar:before,
  .chart .bar-45 .face.side-1 .growing-bar:before,
  input[name='status']:checked + .content article .chart .bar.bar-2 .face.side-1 .growing-bar:before {
    content: "45";
  }
  
  .chart .bar-44 .face.side-0 .growing-bar,
  .chart .bar-44 .face.side-1 .growing-bar {
    -webkit-transform: translateY(56%);
            transform: translateY(56%);
    opacity: .8;
  }
  .chart .bar-44 .face.side-0 .growing-bar:before,
  .chart .bar-44 .face.side-1 .growing-bar:before {
    content: "44";
  }
  
  .chart .bar-43 .face.side-0 .growing-bar,
  .chart .bar-43 .face.side-1 .growing-bar {
    -webkit-transform: translateY(57%);
            transform: translateY(57%);
    opacity: .8;
  }
  .chart .bar-43 .face.side-0 .growing-bar:before,
  .chart .bar-43 .face.side-1 .growing-bar:before {
    content: "43";
  }
  
  .chart .bar-42 .face.side-0 .growing-bar,
  .chart .bar-42 .face.side-1 .growing-bar {
    -webkit-transform: translateY(58%);
            transform: translateY(58%);
    opacity: .8;
  }
  .chart .bar-42 .face.side-0 .growing-bar:before,
  .chart .bar-42 .face.side-1 .growing-bar:before {
    content: "42";
  }
  
  .chart .bar-41 .face.side-0 .growing-bar,
  .chart .bar-41 .face.side-1 .growing-bar {
    -webkit-transform: translateY(59%);
            transform: translateY(59%);
    opacity: .8;
  }
  .chart .bar-41 .face.side-0 .growing-bar:before,
  .chart .bar-41 .face.side-1 .growing-bar:before {
    content: "41";
  }
  
  .chart .bar-40 .face.side-0 .growing-bar,
  .chart .bar-40 .face.side-1 .growing-bar {
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
    opacity: .8;
  }
  .chart .bar-40 .face.side-0 .growing-bar:before,
  .chart .bar-40 .face.side-1 .growing-bar:before {
    content: "40";
  }
  
  .chart .bar-39 .face.side-0 .growing-bar,
  .chart .bar-39 .face.side-1 .growing-bar {
    -webkit-transform: translateY(61%);
            transform: translateY(61%);
    opacity: .8;
  }
  .chart .bar-39 .face.side-0 .growing-bar:before,
  .chart .bar-39 .face.side-1 .growing-bar:before {
    content: "39";
  }
  
  .chart .bar-38 .face.side-0 .growing-bar,
  .chart .bar-38 .face.side-1 .growing-bar {
    -webkit-transform: translateY(62%);
            transform: translateY(62%);
    opacity: .8;
  }
  .chart .bar-38 .face.side-0 .growing-bar:before,
  .chart .bar-38 .face.side-1 .growing-bar:before {
    content: "38";
  }
  
  .chart .bar-37 .face.side-0 .growing-bar,
  .chart .bar-37 .face.side-1 .growing-bar {
    -webkit-transform: translateY(63%);
            transform: translateY(63%);
    opacity: .8;
  }
  .chart .bar-37 .face.side-0 .growing-bar:before,
  .chart .bar-37 .face.side-1 .growing-bar:before {
    content: "37";
  }
  
  .chart .bar-36 .face.side-0 .growing-bar,
  .chart .bar-36 .face.side-1 .growing-bar {
    -webkit-transform: translateY(64%);
            transform: translateY(64%);
    opacity: .8;
  }
  .chart .bar-36 .face.side-0 .growing-bar:before,
  .chart .bar-36 .face.side-1 .growing-bar:before {
    content: "36";
  }
  
  .chart .bar-35 .face.side-0 .growing-bar,
  .chart .bar-35 .face.side-1 .growing-bar {
    -webkit-transform: translateY(65%);
            transform: translateY(65%);
    opacity: .8;
  }
  .chart .bar-35 .face.side-0 .growing-bar:before,
  .chart .bar-35 .face.side-1 .growing-bar:before {
    content: "35";
  }
  
  .chart .bar-34 .face.side-0 .growing-bar,
  .chart .bar-34 .face.side-1 .growing-bar {
    -webkit-transform: translateY(66%);
            transform: translateY(66%);
    opacity: .8;
  }
  .chart .bar-34 .face.side-0 .growing-bar:before,
  .chart .bar-34 .face.side-1 .growing-bar:before {
    content: "34";
  }
  
  .chart .bar-33 .face.side-0 .growing-bar,
  .chart .bar-33 .face.side-1 .growing-bar {
    -webkit-transform: translateY(67%);
            transform: translateY(67%);
    opacity: .8;
  }
  .chart .bar-33 .face.side-0 .growing-bar:before,
  .chart .bar-33 .face.side-1 .growing-bar:before {
    content: "33";
  }
  
  .chart .bar-32 .face.side-0 .growing-bar,
  .chart .bar-32 .face.side-1 .growing-bar {
    -webkit-transform: translateY(68%);
            transform: translateY(68%);
    opacity: .8;
  }
  .chart .bar-32 .face.side-0 .growing-bar:before,
  .chart .bar-32 .face.side-1 .growing-bar:before {
    content: "32";
  }
  
  .chart .bar-31 .face.side-0 .growing-bar,
  .chart .bar-31 .face.side-1 .growing-bar {
    -webkit-transform: translateY(69%);
            transform: translateY(69%);
    opacity: .8;
  }
  .chart .bar-31 .face.side-0 .growing-bar:before,
  .chart .bar-31 .face.side-1 .growing-bar:before {
    content: "31";
  }
  
  .chart .bar-30 .face.side-0 .growing-bar,
  .chart .bar-30 .face.side-1 .growing-bar {
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
    opacity: .8;
  }
  .chart .bar-30 .face.side-0 .growing-bar:before,
  .chart .bar-30 .face.side-1 .growing-bar:before {
    content: "30";
  }
  
  .chart .bar-29 .face.side-0 .growing-bar,
  .chart .bar-29 .face.side-1 .growing-bar {
    -webkit-transform: translateY(71%);
            transform: translateY(71%);
    opacity: .8;
  }
  .chart .bar-29 .face.side-0 .growing-bar:before,
  .chart .bar-29 .face.side-1 .growing-bar:before {
    content: "29";
  }
  
  .chart .bar-28 .face.side-0 .growing-bar,
  .chart .bar-28 .face.side-1 .growing-bar {
    -webkit-transform: translateY(72%);
            transform: translateY(72%);
    opacity: .8;
  }
  .chart .bar-28 .face.side-0 .growing-bar:before,
  .chart .bar-28 .face.side-1 .growing-bar:before {
    content: "28";
  }
  
  .chart .bar-27 .face.side-0 .growing-bar,
  .chart .bar-27 .face.side-1 .growing-bar {
    -webkit-transform: translateY(73%);
            transform: translateY(73%);
    opacity: .8;
  }
  .chart .bar-27 .face.side-0 .growing-bar:before,
  .chart .bar-27 .face.side-1 .growing-bar:before {
    content: "27";
  }
  
  .chart .bar-26 .face.side-0 .growing-bar,
  .chart .bar-26 .face.side-1 .growing-bar {
    -webkit-transform: translateY(74%);
            transform: translateY(74%);
    opacity: .8;
  }
  .chart .bar-26 .face.side-0 .growing-bar:before,
  .chart .bar-26 .face.side-1 .growing-bar:before {
    content: "26";
  }
  
  .chart .bar-25 .face.side-0 .growing-bar, input[name='status']:checked + .content article .chart .bar.bar-1 .face.side-0 .growing-bar,
  .chart .bar-25 .face.side-1 .growing-bar,
  input[name='status']:checked + .content article .chart .bar.bar-1 .face.side-1 .growing-bar {
    -webkit-transform: translateY(75%);
            transform: translateY(75%);
    opacity: .8;
  }
  .chart .bar-25 .face.side-0 .growing-bar:before, input[name='status']:checked + .content article .chart .bar.bar-1 .face.side-0 .growing-bar:before,
  .chart .bar-25 .face.side-1 .growing-bar:before,
  input[name='status']:checked + .content article .chart .bar.bar-1 .face.side-1 .growing-bar:before {
    content: "25";
  }
  
  .chart .bar-24 .face.side-0 .growing-bar,
  .chart .bar-24 .face.side-1 .growing-bar {
    -webkit-transform: translateY(76%);
            transform: translateY(76%);
    opacity: .8;
  }
  .chart .bar-24 .face.side-0 .growing-bar:before,
  .chart .bar-24 .face.side-1 .growing-bar:before {
    content: "24";
  }
  
  .chart .bar-23 .face.side-0 .growing-bar,
  .chart .bar-23 .face.side-1 .growing-bar {
    -webkit-transform: translateY(77%);
            transform: translateY(77%);
    opacity: .8;
  }
  .chart .bar-23 .face.side-0 .growing-bar:before,
  .chart .bar-23 .face.side-1 .growing-bar:before {
    content: "23";
  }
  
  .chart .bar-22 .face.side-0 .growing-bar,
  .chart .bar-22 .face.side-1 .growing-bar {
    -webkit-transform: translateY(78%);
            transform: translateY(78%);
    opacity: .8;
  }
  .chart .bar-22 .face.side-0 .growing-bar:before,
  .chart .bar-22 .face.side-1 .growing-bar:before {
    content: "22";
  }
  
  .chart .bar-21 .face.side-0 .growing-bar,
  .chart .bar-21 .face.side-1 .growing-bar {
    -webkit-transform: translateY(79%);
            transform: translateY(79%);
    opacity: .8;
  }
  .chart .bar-21 .face.side-0 .growing-bar:before,
  .chart .bar-21 .face.side-1 .growing-bar:before {
    content: "21";
  }
  
  .chart .bar-20 .face.side-0 .growing-bar,
  .chart .bar-20 .face.side-1 .growing-bar {
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
    opacity: .8;
  }
  .chart .bar-20 .face.side-0 .growing-bar:before,
  .chart .bar-20 .face.side-1 .growing-bar:before {
    content: "20";
  }
  
  .chart .bar-19 .face.side-0 .growing-bar,
  .chart .bar-19 .face.side-1 .growing-bar {
    -webkit-transform: translateY(81%);
            transform: translateY(81%);
    opacity: .8;
  }
  .chart .bar-19 .face.side-0 .growing-bar:before,
  .chart .bar-19 .face.side-1 .growing-bar:before {
    content: "19";
  }
  
  .chart .bar-18 .face.side-0 .growing-bar,
  .chart .bar-18 .face.side-1 .growing-bar {
    -webkit-transform: translateY(82%);
            transform: translateY(82%);
    opacity: .8;
  }
  .chart .bar-18 .face.side-0 .growing-bar:before,
  .chart .bar-18 .face.side-1 .growing-bar:before {
    content: "18";
  }
  
  .chart .bar-17 .face.side-0 .growing-bar,
  .chart .bar-17 .face.side-1 .growing-bar {
    -webkit-transform: translateY(83%);
            transform: translateY(83%);
    opacity: .8;
  }
  .chart .bar-17 .face.side-0 .growing-bar:before,
  .chart .bar-17 .face.side-1 .growing-bar:before {
    content: "17";
  }
  
  .chart .bar-16 .face.side-0 .growing-bar,
  .chart .bar-16 .face.side-1 .growing-bar {
    -webkit-transform: translateY(84%);
            transform: translateY(84%);
    opacity: .8;
  }
  .chart .bar-16 .face.side-0 .growing-bar:before,
  .chart .bar-16 .face.side-1 .growing-bar:before {
    content: "16";
  }
  
  .chart .bar-15 .face.side-0 .growing-bar,
  .chart .bar-15 .face.side-1 .growing-bar {
    -webkit-transform: translateY(85%);
            transform: translateY(85%);
    opacity: .8;
  }
  .chart .bar-15 .face.side-0 .growing-bar:before,
  .chart .bar-15 .face.side-1 .growing-bar:before {
    content: "15";
  }
  
  .chart .bar-14 .face.side-0 .growing-bar,
  .chart .bar-14 .face.side-1 .growing-bar {
    -webkit-transform: translateY(86%);
            transform: translateY(86%);
    opacity: .8;
  }
  .chart .bar-14 .face.side-0 .growing-bar:before,
  .chart .bar-14 .face.side-1 .growing-bar:before {
    content: "14";
  }
  
  .chart .bar-13 .face.side-0 .growing-bar,
  .chart .bar-13 .face.side-1 .growing-bar {
    -webkit-transform: translateY(87%);
            transform: translateY(87%);
    opacity: .8;
  }
  .chart .bar-13 .face.side-0 .growing-bar:before,
  .chart .bar-13 .face.side-1 .growing-bar:before {
    content: "13";
  }
  
  .chart .bar-12 .face.side-0 .growing-bar,
  .chart .bar-12 .face.side-1 .growing-bar {
    -webkit-transform: translateY(88%);
            transform: translateY(88%);
    opacity: .8;
  }
  .chart .bar-12 .face.side-0 .growing-bar:before,
  .chart .bar-12 .face.side-1 .growing-bar:before {
    content: "12";
  }
  
  .chart .bar-11 .face.side-0 .growing-bar,
  .chart .bar-11 .face.side-1 .growing-bar {
    -webkit-transform: translateY(89%);
            transform: translateY(89%);
    opacity: .8;
  }
  .chart .bar-11 .face.side-0 .growing-bar:before,
  .chart .bar-11 .face.side-1 .growing-bar:before {
    content: "11";
  }
  
  .chart .bar-10 .face.side-0 .growing-bar,
  .chart .bar-10 .face.side-1 .growing-bar {
    -webkit-transform: translateY(90%);
            transform: translateY(90%);
    opacity: .8;
  }
  .chart .bar-10 .face.side-0 .growing-bar:before,
  .chart .bar-10 .face.side-1 .growing-bar:before {
    content: "10";
  }
  
  .chart .bar-9 .face.side-0 .growing-bar,
  .chart .bar-9 .face.side-1 .growing-bar {
    -webkit-transform: translateY(91%);
            transform: translateY(91%);
    opacity: .8;
  }
  .chart .bar-9 .face.side-0 .growing-bar:before,
  .chart .bar-9 .face.side-1 .growing-bar:before {
    content: "9";
  }
  
  .chart .bar-8 .face.side-0 .growing-bar,
  .chart .bar-8 .face.side-1 .growing-bar {
    -webkit-transform: translateY(92%);
            transform: translateY(92%);
    opacity: .8;
  }
  .chart .bar-8 .face.side-0 .growing-bar:before,
  .chart .bar-8 .face.side-1 .growing-bar:before {
    content: "8";
  }
  
  .chart .bar-7 .face.side-0 .growing-bar,
  .chart .bar-7 .face.side-1 .growing-bar {
    -webkit-transform: translateY(93%);
            transform: translateY(93%);
    opacity: .8;
  }
  .chart .bar-7 .face.side-0 .growing-bar:before,
  .chart .bar-7 .face.side-1 .growing-bar:before {
    content: "7";
  }
  
  .chart .bar-6 .face.side-0 .growing-bar,
  .chart .bar-6 .face.side-1 .growing-bar {
    -webkit-transform: translateY(94%);
            transform: translateY(94%);
    opacity: .8;
  }
  .chart .bar-6 .face.side-0 .growing-bar:before,
  .chart .bar-6 .face.side-1 .growing-bar:before {
    content: "6";
  }
  
  .chart .bar-5 .face.side-0 .growing-bar,
  .chart .bar-5 .face.side-1 .growing-bar {
    -webkit-transform: translateY(95%);
            transform: translateY(95%);
    opacity: .8;
  }
  .chart .bar-5 .face.side-0 .growing-bar:before,
  .chart .bar-5 .face.side-1 .growing-bar:before {
    content: "5";
  }
  
  .chart .bar-4 .face.side-0 .growing-bar,
  .chart .bar-4 .face.side-1 .growing-bar {
    -webkit-transform: translateY(96%);
            transform: translateY(96%);
    opacity: .8;
  }
  .chart .bar-4 .face.side-0 .growing-bar:before,
  .chart .bar-4 .face.side-1 .growing-bar:before {
    content: "4";
  }
  
  .chart .bar-3 .face.side-0 .growing-bar,
  .chart .bar-3 .face.side-1 .growing-bar {
    -webkit-transform: translateY(97%);
            transform: translateY(97%);
    opacity: .8;
  }
  .chart .bar-3 .face.side-0 .growing-bar:before,
  .chart .bar-3 .face.side-1 .growing-bar:before {
    content: "3";
  }
  
  .chart .bar-2 .face.side-0 .growing-bar,
  .chart .bar-2 .face.side-1 .growing-bar {
    -webkit-transform: translateY(98%);
            transform: translateY(98%);
    opacity: .8;
  }
  .chart .bar-2 .face.side-0 .growing-bar:before,
  .chart .bar-2 .face.side-1 .growing-bar:before {
    content: "2";
  }
  
  .chart .bar-1 .face.side-0 .growing-bar,
  .chart .bar-1 .face.side-1 .growing-bar {
    -webkit-transform: translateY(99%);
            transform: translateY(99%);
    opacity: .8;
  }
  .chart .bar-1 .face.side-0 .growing-bar:before,
  .chart .bar-1 .face.side-1 .growing-bar:before {
    content: "1";
  }
  
  /*
  END // CHART'S RULES
  *
  *
  */
  .legend {
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  label {
    box-sizing: border-box;
    padding: 1em;
    cursor: pointer;
    transition: all .15s ease-in-out;
    color: #0a4069;
    border: 1px solid rgba(254, 254, 254, 0.6);
    border-radius: 0;
    flex: 0 0 6em;
  }
  label:first-child {
    mEXNin-right: 0;
    border-radius: .2em 0 0 .2em;
  }
  label:last-child {
    mEXNin-left: 0;
    border-radius: 0 .2em .2em 0;
  }
  
  input[name='status']:checked + .content {
    z-index: 10;
  }
  input[name='status']:checked + .content article {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  input[name='status']:checked + .content article .chart .bar .growing-bar {
    transition-delay: .3s;
    transition-duration: .6s;
  }
  
  input[id='status-1']:checked ~ .legend > label[for='status-1'],
  input[id='status-2']:checked ~ .legend > label[for='status-2'],
  input[id='status-3']:checked ~ .legend > label[for='status-3'],
  input[id='double-size']:checked ~ label[for='double-size'] {
    color: #76c900;
    border: 1px solid #031523;
    background-color: #0a4069;
  }
  
  input[id='double-size']:checked + .small-font-size {
    font-size: 2em;
  }
  
  input[name='set-value']:checked ~ .content article label[for='set-value'] {
    color: #76c900;
    border: 1px solid #031523;
    background-color: #0a4069;
  }
  input[name='set-value']:checked ~ .content article .chart .bar.bar-1 .face.side-0 .growing-bar,
  input[name='set-value']:checked ~ .content article .chart .bar.bar-1 .face.side-1 .growing-bar, input[name='set-value']:checked ~ .content article .chart .bar.bar-1-1 .face.side-0 .growing-bar,
  input[name='set-value']:checked ~ .content article .chart .bar.bar-1-1 .face.side-1 .growing-bar {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  input[name='set-value']:checked ~ .content article .chart .bar.bar-1 .face.side-0 .growing-bar:before,
  input[name='set-value']:checked ~ .content article .chart .bar.bar-1 .face.side-1 .growing-bar:before, input[name='set-value']:checked ~ .content article .chart .bar.bar-1-1 .face.side-0 .growing-bar:before,
  input[name='set-value']:checked ~ .content article .chart .bar.bar-1-1 .face.side-1 .growing-bar:before {
    content: '85';
  }
  




  /*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
  }
  
  @-ms-viewport {
    width: device-width;
  }
  
  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  
  body {
    mEXNin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }
  
  [tabindex="-1"]:focus {
    outline: 0 !important;
  }
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  
  h1, h2, h3, h4, h5, h6 {
    mEXNin-top: 0;
    mEXNin-bottom: 0.5rem;
  }
  
  p {
    mEXNin-top: 0;
    mEXNin-bottom: 1rem;
  }
  
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }
  
  address {
    mEXNin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  
  ol,
  ul,
  dl {
    mEXNin-top: 0;
    mEXNin-bottom: 1rem;
  }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    mEXNin-bottom: 0;
  }
  
  dt {
    font-weight: 700;
  }
  
  dd {
    mEXNin-bottom: .5rem;
    mEXNin-left: 0;
  }
  
  blockquote {
    mEXNin: 0 0 1rem;
  }
  
  dfn {
    font-style: italic;
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  small {
    font-size: 80%;
  }
  
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -.25em;
  }
  
  sup {
    top: -.5em;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }
  
  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }
  
  pre,
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  
  pre {
    mEXNin-top: 0;
    mEXNin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  
  figure {
    mEXNin: 0 0 1rem;
  }
  
  img {
    vertical-align: middle;
    border-style: none;
  }
  
  svg:not(:root) {
    overflow: hidden;
  }
  
  table {
    border-collapse: collapse;
  }
  
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }
  
  th {
    text-align: inherit;
  }
  
  label {
    display: inline-block;
    mEXNin-bottom: .5rem;
  }
  
  button {
    border-radius: 0;
  }
  
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    mEXNin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  button,
  input {
    overflow: visible;
  }
  
  button,
  select {
    text-transform: none;
  }
  
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }
  
  textarea {
    overflow: auto;
    resize: vertical;
  }
  
  fieldset {
    min-width: 0;
    padding: 0;
    mEXNin: 0;
    border: 0;
  }
  
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    mEXNin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  
  progress {
    vertical-align: baseline;
  }
  
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  
  output {
    display: inline-block;
  }
  
  summary {
    display: list-item;
    cursor: pointer;
  }
  
  template {
    display: none;
  }
  
  [hidden] {
    display: none !important;
  }
  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    mEXNin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
  
  h1, .h1 {
    font-size: 2.5rem;
  }
  
  h2, .h2 {
    font-size: 2rem;
  }
  
  h3, .h3 {
    font-size: 1.75rem;
  }
  
  h4, .h4 {
    font-size: 1.5rem;
  }
  
  h5, .h5 {
    font-size: 1.25rem;
  }
  
  h6, .h6 {
    font-size: 1rem;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  hr {
    mEXNin-top: 1rem;
    mEXNin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  small,
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  
  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline-item {
    display: inline-block;
  }
  
  .list-inline-item:not(:last-child) {
    mEXNin-right: 0.5rem;
  }
  
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  
  .blockquote {
    mEXNin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
  }
  
  .blockquote-footer::before {
    content: "\2014 \00A0";
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
  }
  
  .figure {
    display: inline-block;
  }
  
  .figure-img {
    mEXNin-bottom: 0.5rem;
    line-height: 1;
  }
  
  .figure-caption {
    font-size: 90%;
    color: #6c757d;
  }
  
  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
  }
  
  a > code {
    color: inherit;
  }
  
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }
  
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
  
  pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
  }
  
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    mEXNin-right: auto;
    mEXNin-left: auto;
  }
  
  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    mEXNin-right: auto;
    mEXNin-left: auto;
  }
  
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    mEXNin-right: -15px;
    mEXNin-left: -15px;
  }
  
  .no-gutters {
    mEXNin-right: 0;
    mEXNin-left: 0;
  }
  
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  
  .col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  
  .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  
  .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  
  .col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  
  .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  
  .col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  
  .col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  
  .col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  
  .order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  
  .order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  
  .order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  
  .order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  
  .order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  
  .order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  
  .order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  
  .order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  
  .order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  
  .order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  
  .order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  
  .order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  
  .order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  
  .order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  
  .offset-1 {
    mEXNin-left: 8.333333%;
  }
  
  .offset-2 {
    mEXNin-left: 16.666667%;
  }
  
  .offset-3 {
    mEXNin-left: 25%;
  }
  
  .offset-4 {
    mEXNin-left: 33.333333%;
  }
  
  .offset-5 {
    mEXNin-left: 41.666667%;
  }
  
  .offset-6 {
    mEXNin-left: 50%;
  }
  
  .offset-7 {
    mEXNin-left: 58.333333%;
  }
  
  .offset-8 {
    mEXNin-left: 66.666667%;
  }
  
  .offset-9 {
    mEXNin-left: 75%;
  }
  
  .offset-10 {
    mEXNin-left: 83.333333%;
  }
  
  .offset-11 {
    mEXNin-left: 91.666667%;
  }
  
  @media (min-width: 576px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sm-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .order-sm-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    }
    .order-sm-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    }
    .order-sm-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-sm-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .order-sm-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .order-sm-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    }
    .order-sm-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    }
    .order-sm-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    }
    .order-sm-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    }
    .order-sm-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    }
    .order-sm-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    }
    .order-sm-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    }
    .order-sm-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    }
    .order-sm-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-sm-0 {
      mEXNin-left: 0;
    }
    .offset-sm-1 {
      mEXNin-left: 8.333333%;
    }
    .offset-sm-2 {
      mEXNin-left: 16.666667%;
    }
    .offset-sm-3 {
      mEXNin-left: 25%;
    }
    .offset-sm-4 {
      mEXNin-left: 33.333333%;
    }
    .offset-sm-5 {
      mEXNin-left: 41.666667%;
    }
    .offset-sm-6 {
      mEXNin-left: 50%;
    }
    .offset-sm-7 {
      mEXNin-left: 58.333333%;
    }
    .offset-sm-8 {
      mEXNin-left: 66.666667%;
    }
    .offset-sm-9 {
      mEXNin-left: 75%;
    }
    .offset-sm-10 {
      mEXNin-left: 83.333333%;
    }
    .offset-sm-11 {
      mEXNin-left: 91.666667%;
    }
  }
  
  @media (min-width: 768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .order-md-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    }
    .order-md-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    }
    .order-md-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-md-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .order-md-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .order-md-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    }
    .order-md-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    }
    .order-md-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    }
    .order-md-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    }
    .order-md-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    }
    .order-md-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    }
    .order-md-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    }
    .order-md-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    }
    .order-md-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-md-0 {
      mEXNin-left: 0;
    }
    .offset-md-1 {
      mEXNin-left: 8.333333%;
    }
    .offset-md-2 {
      mEXNin-left: 16.666667%;
    }
    .offset-md-3 {
      mEXNin-left: 25%;
    }
    .offset-md-4 {
      mEXNin-left: 33.333333%;
    }
    .offset-md-5 {
      mEXNin-left: 41.666667%;
    }
    .offset-md-6 {
      mEXNin-left: 50%;
    }
    .offset-md-7 {
      mEXNin-left: 58.333333%;
    }
    .offset-md-8 {
      mEXNin-left: 66.666667%;
    }
    .offset-md-9 {
      mEXNin-left: 75%;
    }
    .offset-md-10 {
      mEXNin-left: 83.333333%;
    }
    .offset-md-11 {
      mEXNin-left: 91.666667%;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-lg-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .order-lg-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    }
    .order-lg-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    }
    .order-lg-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-lg-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .order-lg-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .order-lg-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    }
    .order-lg-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    }
    .order-lg-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    }
    .order-lg-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    }
    .order-lg-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    }
    .order-lg-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    }
    .order-lg-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    }
    .order-lg-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    }
    .order-lg-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-lg-0 {
      mEXNin-left: 0;
    }
    .offset-lg-1 {
      mEXNin-left: 8.333333%;
    }
    .offset-lg-2 {
      mEXNin-left: 16.666667%;
    }
    .offset-lg-3 {
      mEXNin-left: 25%;
    }
    .offset-lg-4 {
      mEXNin-left: 33.333333%;
    }
    .offset-lg-5 {
      mEXNin-left: 41.666667%;
    }
    .offset-lg-6 {
      mEXNin-left: 50%;
    }
    .offset-lg-7 {
      mEXNin-left: 58.333333%;
    }
    .offset-lg-8 {
      mEXNin-left: 66.666667%;
    }
    .offset-lg-9 {
      mEXNin-left: 75%;
    }
    .offset-lg-10 {
      mEXNin-left: 83.333333%;
    }
    .offset-lg-11 {
      mEXNin-left: 91.666667%;
    }
  }
  
  @media (min-width: 1200px) {
    .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-xl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
    .order-xl-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    }
    .order-xl-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    }
    .order-xl-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-xl-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    .order-xl-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    }
    .order-xl-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    }
    .order-xl-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    }
    .order-xl-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    }
    .order-xl-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    }
    .order-xl-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    }
    .order-xl-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    }
    .order-xl-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    }
    .order-xl-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    }
    .order-xl-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-xl-0 {
      mEXNin-left: 0;
    }
    .offset-xl-1 {
      mEXNin-left: 8.333333%;
    }
    .offset-xl-2 {
      mEXNin-left: 16.666667%;
    }
    .offset-xl-3 {
      mEXNin-left: 25%;
    }
    .offset-xl-4 {
      mEXNin-left: 33.333333%;
    }
    .offset-xl-5 {
      mEXNin-left: 41.666667%;
    }
    .offset-xl-6 {
      mEXNin-left: 50%;
    }
    .offset-xl-7 {
      mEXNin-left: 58.333333%;
    }
    .offset-xl-8 {
      mEXNin-left: 66.666667%;
    }
    .offset-xl-9 {
      mEXNin-left: 75%;
    }
    .offset-xl-10 {
      mEXNin-left: 83.333333%;
    }
    .offset-xl-11 {
      mEXNin-left: 91.666667%;
    }
  }
  
  .table {
    width: 100%;
    max-width: 100%;
    mEXNin-bottom: 1rem;
    background-color: transparent;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table .table {
    background-color: #fff;
  }
  
  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }
  
  .table-bordered {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #b8daff;
  }
  
  .table-hover .table-primary:hover {
    background-color: #9fcdff;
  }
  
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff;
  }
  
  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
    background-color: #d6d8db;
  }
  
  .table-hover .table-secondary:hover {
    background-color: #c8cbcf;
  }
  
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf;
  }
  
  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #c3e6cb;
  }
  
  .table-hover .table-success:hover {
    background-color: #b1dfbb;
  }
  
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb;
  }
  
  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #bee5eb;
  }
  
  .table-hover .table-info:hover {
    background-color: #abdde5;
  }
  
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5;
  }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #ffeeba;
  }
  
  .table-hover .table-warning:hover {
    background-color: #ffe8a1;
  }
  
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
  }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #f5c6cb;
  }
  
  .table-hover .table-danger:hover {
    background-color: #f1b0b7;
  }
  
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
  }
  
  .table-light,
  .table-light > th,
  .table-light > td {
    background-color: #fdfdfe;
  }
  
  .table-hover .table-light:hover {
    background-color: #ececf6;
  }
  
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6;
  }
  
  .table-dark,
  .table-dark > th,
  .table-dark > td {
    background-color: #c6c8ca;
  }
  
  .table-hover .table-dark:hover {
    background-color: #b9bbbe;
  }
  
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe;
  }
  
  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
  }
  
  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .table-dark {
    color: #fff;
    background-color: #212529;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e;
  }
  
  .table-dark.table-bordered {
    border: 0;
  }
  
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
  }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  
  .table-responsive > .table-bordered {
    border: 0;
  }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  
  select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
  }
  
  select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }
  
  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }
  
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    mEXNin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  
  .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    mEXNin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }
  
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0;
  }
  
  .form-control-sm, .input-group-sm > .form-control,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px);
  }
  
  .form-control-lg, .input-group-lg > .form-control,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  
  select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
  .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
  .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
  .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px);
  }
  
  .form-group {
    mEXNin-bottom: 1rem;
  }
  
  .form-text {
    display: block;
    mEXNin-top: 0.25rem;
  }
  
  .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    mEXNin-right: -5px;
    mEXNin-left: -5px;
  }
  
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  
  .form-check-input {
    position: absolute;
    mEXNin-top: 0.3rem;
    mEXNin-left: -1.25rem;
  }
  
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
  }
  
  .form-check-label {
    mEXNin-bottom: 0;
  }
  
  .form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    mEXNin-right: 0.75rem;
  }
  
  .form-check-inline .form-check-input {
    position: static;
    mEXNin-top: 0;
    mEXNin-right: 0.3125rem;
    mEXNin-left: 0;
  }
  
  .valid-feedback {
    display: none;
    width: 100%;
    mEXNin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
  }
  
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    mEXNin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.8);
    border-radius: .2rem;
  }
  
  .was-validated .form-control:valid, .form-control.is-valid, .was-validated
  .custom-select:valid,
  .custom-select.is-valid {
    border-color: #28a745;
  }
  
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745;
  }
  
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a;
  }
  
  .was-validated .custom-control-input:valid ~ .valid-feedback,
  .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
  .custom-control-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #34ce57;
  }
  
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  
  .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745;
  }
  
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit;
  }
  
  .was-validated .custom-file-input:valid ~ .valid-feedback,
  .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
  .custom-file-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  
  .invalid-feedback {
    display: none;
    width: 100%;
    mEXNin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    mEXNin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.8);
    border-radius: .2rem;
  }
  
  .was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
  .custom-select:invalid,
  .custom-select.is-invalid {
    border-color: #dc3545;
  }
  
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545;
  }
  
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9;
  }
  
  .was-validated .custom-control-input:invalid ~ .invalid-feedback,
  .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
  .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #e4606d;
  }
  
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545;
  }
  
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit;
  }
  
  .was-validated .custom-file-input:invalid ~ .invalid-feedback,
  .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
  .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .form-inline .form-check {
    width: 100%;
  }
  
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      mEXNin-bottom: 0;
    }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      mEXNin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .input-group {
      width: auto;
    }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      mEXNin-top: 0;
      mEXNin-right: 0.25rem;
      mEXNin-left: 0;
    }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      mEXNin-bottom: 0;
    }
  }
  
  .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .btn:hover, .btn:focus {
    text-decoration: none;
  }
  
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
  }
  
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
  }
  
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  
  .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
  
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
  
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  
  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
  }
  
  .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
  
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
  }
  
  .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
  
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
  }
  
  .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
  }
  
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
  }
  
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
  }
  
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  
  .btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745;
  }
  
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
  }
  
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
  }
  
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }
  
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
  }
  
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
  }
  
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  .btn-block {
    display: block;
    width: 100%;
  }
  
  .btn-block + .btn-block {
    mEXNin-top: 0.5rem;
  }
  
  input[type="submit"].btn-block,
  input[type="reset"].btn-block,
  input[type="button"].btn-block {
    width: 100%;
  }
  
  .fade {
    opacity: 0;
    transition: opacity 0.15s linear;
  }
  
  .fade.show {
    opacity: 1;
  }
  
  .collapse {
    display: none;
  }
  
  .collapse.show {
    display: block;
  }
  
  tr.collapse.show {
    display: table-row;
  }
  
  tbody.collapse.show {
    display: table-row-group;
  }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  
  .dropup,
  .dropdown {
    position: relative;
  }
  
  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    mEXNin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
  .dropdown-toggle:empty::after {
    mEXNin-left: 0;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    mEXNin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  
  .dropup .dropdown-menu {
    mEXNin-top: 0;
    mEXNin-bottom: 0.125rem;
  }
  
  .dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    mEXNin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  
  .dropup .dropdown-toggle:empty::after {
    mEXNin-left: 0;
  }
  
  .dropright .dropdown-menu {
    mEXNin-top: 0;
    mEXNin-left: 0.125rem;
  }
  
  .dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    mEXNin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  
  .dropright .dropdown-toggle:empty::after {
    mEXNin-left: 0;
  }
  
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  
  .dropleft .dropdown-menu {
    mEXNin-top: 0;
    mEXNin-right: 0.125rem;
  }
  
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    mEXNin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    mEXNin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  
  .dropleft .dropdown-toggle:empty::after {
    mEXNin-left: 0;
  }
  
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  
  .dropdown-divider {
    height: 0;
    mEXNin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }
  
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    mEXNin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
  }
  
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
  }
  
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  
  .btn-group > .btn:hover,
  .btn-group-vertical > .btn:hover {
    z-index: 1;
  }
  
  .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1;
  }
  
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    mEXNin-left: -1px;
  }
  
  .btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .btn-toolbar .input-group {
    width: auto;
  }
  
  .btn-group > .btn:first-child {
    mEXNin-left: 0;
  }
  
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
  }
  
  .dropdown-toggle-split::after {
    mEXNin-left: 0;
  }
  
  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
  }
  
  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  
  .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%;
  }
  
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    mEXNin-top: -1px;
    mEXNin-left: 0;
  }
  
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    mEXNin-bottom: 0;
  }
  
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  
  .input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
  }
  
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    mEXNin-bottom: 0;
  }
  
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file:focus {
    z-index: 3;
  }
  
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .custom-file,
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .custom-file {
    mEXNin-left: -1px;
  }
  
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .input-group > .custom-file:not(:last-child) .custom-file-label,
  .input-group > .custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .custom-file:not(:first-child) .custom-file-label,
  .input-group > .custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .input-group-prepend,
  .input-group-append {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2;
  }
  
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    mEXNin-left: -1px;
  }
  
  .input-group-prepend {
    mEXNin-right: -1px;
  }
  
  .input-group-append {
    mEXNin-left: -1px;
  }
  
  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    mEXNin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    mEXNin-top: 0;
  }
  
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
  }
  
  .custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    mEXNin-right: 1rem;
  }
  
  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff;
  }
  
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
  }
  
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
  }
  
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
  
  .custom-control-label {
    mEXNin-bottom: 0;
  }
  
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6;
  }
  
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  
  .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #007bff;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #007bff;
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
  }
  
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #007bff;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  }
  
  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
  }
  
  .custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }
  
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
  }
  
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
  }
  
  .custom-select::-ms-expand {
    opacity: 0;
  }
  
  .custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
  }
  
  .custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
  }
  
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    mEXNin-bottom: 0;
  }
  
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    mEXNin: 0;
    opacity: 0;
  }
  
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-file-input:focus ~ .custom-file-control::before {
    border-color: #80bdff;
  }
  
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }
  
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
  }
  
  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    mEXNin-bottom: 0;
    list-style: none;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  
  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
  }
  
  .nav-link.disabled {
    color: #6c757d;
  }
  
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  
  .nav-tabs .nav-item {
    mEXNin-bottom: -1px;
  }
  
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  .nav-tabs .dropdown-menu {
    mEXNin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff;
  }
  
  .nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
  
  .tab-content > .tab-pane {
    display: none;
  }
  
  .tab-content > .active {
    display: block;
  }
  
  .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    mEXNin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
  }
  
  .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    mEXNin-bottom: 0;
    list-style: none;
  }
  
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
  }
  
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  
  .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  
  .navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  
  .navbar-expand .navbar-toggler {
    display: none;
  }
  
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
  
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
  }
  
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
  }
  
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  
  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
  
  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  
  .card > hr {
    mEXNin-right: 0;
    mEXNin-left: 0;
  }
  
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  
  .card-title {
    mEXNin-bottom: 0.75rem;
  }
  
  .card-subtitle {
    mEXNin-top: -0.375rem;
    mEXNin-bottom: 0;
  }
  
  .card-text:last-child {
    mEXNin-bottom: 0;
  }
  
  .card-link:hover {
    text-decoration: none;
  }
  
  .card-link + .card-link {
    mEXNin-left: 1.25rem;
  }
  
  .card-header {
    padding: 0.75rem 1.25rem;
    mEXNin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0;
  }
  
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }
  
  .card-header-tabs {
    mEXNin-right: -0.625rem;
    mEXNin-bottom: -0.75rem;
    mEXNin-left: -0.625rem;
    border-bottom: 0;
  }
  
  .card-header-pills {
    mEXNin-right: -0.625rem;
    mEXNin-left: -0.625rem;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  
  .card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
  }
  
  .card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .card-deck .card {
    mEXNin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      mEXNin-right: -15px;
      mEXNin-left: -15px;
    }
    .card-deck .card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      mEXNin-right: 15px;
      mEXNin-bottom: 0;
      mEXNin-left: 15px;
    }
  }
  
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .card-group > .card {
    mEXNin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      mEXNin-bottom: 0;
    }
    .card-group > .card + .card {
      mEXNin-left: 0;
      border-left: 0;
    }
    .card-group > .card:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:first-child .card-img-top,
    .card-group > .card:first-child .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:first-child .card-img-bottom,
    .card-group > .card:first-child .card-footer {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:last-child .card-img-top,
    .card-group > .card:last-child .card-header {
      border-top-left-radius: 0;
    }
    .card-group > .card:last-child .card-img-bottom,
    .card-group > .card:last-child .card-footer {
      border-bottom-left-radius: 0;
    }
    .card-group > .card:only-child {
      border-radius: 0.25rem;
    }
    .card-group > .card:only-child .card-img-top,
    .card-group > .card:only-child .card-header {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    .card-group > .card:only-child .card-img-bottom,
    .card-group > .card:only-child .card-footer {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
      border-radius: 0;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
      border-radius: 0;
    }
  }
  
  .card-columns .card {
    mEXNin-bottom: 0.75rem;
  }
  
  @media (min-width: 576px) {
    .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  
  .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    mEXNin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: "/";
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
  }
  
  .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
  }
  
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    mEXNin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }
  
  .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  .page-item:first-child .page-link {
    mEXNin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }
  
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
  }
  
  .badge:empty {
    display: none;
  }
  
  .btn .badge {
    position: relative;
    top: -1px;
  }
  
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }
  
  .badge-primary {
    color: #fff;
    background-color: #007bff;
  }
  
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc;
  }
  
  .badge-secondary {
    color: #fff;
    background-color: #6c757d;
  }
  
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62;
  }
  
  .badge-success {
    color: #fff;
    background-color: #28a745;
  }
  
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34;
  }
  
  .badge-info {
    color: #fff;
    background-color: #17a2b8;
  }
  
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b;
  }
  
  .badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00;
  }
  
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130;
  }
  
  .badge-light {
    color: #212529;
    background-color: #f8f9fa;
  }
  
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
  }
  
  .badge-dark {
    color: #fff;
    background-color: #343a40;
  }
  
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124;
  }
  
  .jumbotron {
    padding: 2rem 1rem;
    mEXNin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
  }
  
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }
  
  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    mEXNin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .alert-heading {
    color: inherit;
  }
  
  .alert-link {
    font-weight: 700;
  }
  
  .alert-dismissible {
    padding-right: 4rem;
  }
  
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  
  .alert-primary hr {
    border-top-color: #9fcdff;
  }
  
  .alert-primary .alert-link {
    color: #002752;
  }
  
  .alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
  }
  
  .alert-secondary hr {
    border-top-color: #c8cbcf;
  }
  
  .alert-secondary .alert-link {
    color: #202326;
  }
  
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  .alert-success hr {
    border-top-color: #b1dfbb;
  }
  
  .alert-success .alert-link {
    color: #0b2e13;
  }
  
  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  
  .alert-info hr {
    border-top-color: #abdde5;
  }
  
  .alert-info .alert-link {
    color: #062c33;
  }
  
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  
  .alert-warning hr {
    border-top-color: #ffe8a1;
  }
  
  .alert-warning .alert-link {
    color: #533f03;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .alert-danger hr {
    border-top-color: #f1b0b7;
  }
  
  .alert-danger .alert-link {
    color: #491217;
  }
  
  .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }
  
  .alert-light hr {
    border-top-color: #ececf6;
  }
  
  .alert-light .alert-link {
    color: #686868;
  }
  
  .alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
  }
  
  .alert-dark hr {
    border-top-color: #b9bbbe;
  }
  
  .alert-dark .alert-link {
    color: #040505;
  }
  
  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #007bff;
    transition: width 0.6s ease;
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
  }
  
  .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    mEXNin-bottom: 0;
  }
  
  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }
  
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
  }
  
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    mEXNin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .list-group-item:last-child {
    mEXNin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none;
  }
  
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
  }
  
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  
  .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
  }
  
  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
  }
  
  .list-group-item-primary {
    color: #004085;
    background-color: #b8daff;
  }
  
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff;
  }
  
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085;
  }
  
  .list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
  }
  
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf;
  }
  
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41;
  }
  
  .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb;
  }
  
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb;
  }
  
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724;
  }
  
  .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
  }
  
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
  }
  
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
  }
  
  .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
  }
  
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
  }
  
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
  }
  
  .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
  }
  
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
  }
  
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
  }
  
  .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
  }
  
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
  }
  
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
  }
  
  .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
  }
  
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe;
  }
  
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
  }
  
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
  }
  
  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  
  .modal-open {
    overflow: hidden;
  }
  
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    mEXNin: 0.5rem;
    pointer-events: none;
  }
  
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
  }
  
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  
  .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
  }
  
  .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
  }
  
  .modal-backdrop.fade {
    opacity: 0;
  }
  
  .modal-backdrop.show {
    opacity: 0.5;
  }
  
  .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  
  .modal-header .close {
    padding: 1rem;
    mEXNin: -1rem -1rem -1rem auto;
  }
  
  .modal-title {
    mEXNin-bottom: 0;
    line-height: 1.5;
  }
  
  .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
  }
  
  .modal-footer > :not(:first-child) {
    mEXNin-left: .25rem;
  }
  
  .modal-footer > :not(:last-child) {
    mEXNin-right: .25rem;
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      mEXNin: 1.75rem auto;
    }
    .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2));
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 800px;
    }
  }
  
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    mEXNin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  
  .tooltip.show {
    opacity: 0.9;
  }
  
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  
  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
  }
  
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
  }
  
  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }
  
  .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
  }
  
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  
  .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
  }
  
  .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
  }
  
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
  }
  
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
  }
  
  .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
  }
  
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  
  .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
  }
  
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
  }
  
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
  }
  
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    mEXNin: 0 0.3rem;
  }
  
  .popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    mEXNin-bottom: 0.5rem;
  }
  
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1);
  }
  
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
  }
  
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff;
  }
  
  .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    mEXNin-left: 0.5rem;
  }
  
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    mEXNin: 0.3rem 0;
  }
  
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
  }
  
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff;
  }
  
  .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    mEXNin-top: 0.5rem;
  }
  
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1);
  }
  
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }
  
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
  }
  
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    mEXNin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
  }
  
  .bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    mEXNin-right: 0.5rem;
  }
  
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    mEXNin: 0.3rem 0;
  }
  
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
  }
  
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff;
  }
  
  .popover-header {
    padding: 0.5rem 0.75rem;
    mEXNin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }
  
  .popover-header:empty {
    display: none;
  }
  
  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
  }
  
  .carousel {
    position: relative;
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-item {
    position: relative;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  
  .carousel-item-next,
  .carousel-item-prev {
    position: absolute;
    top: 0;
  }
  
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }
  
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
  }
  
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }
  
  .carousel-control-prev {
    left: 0;
  }
  
  .carousel-control-next {
    right: 0;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
  }
  
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }
  
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    mEXNin-right: 15%;
    mEXNin-left: 15%;
    list-style: none;
  }
  
  .carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    mEXNin-right: 3px;
    mEXNin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
  }
  
  .carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
  }
  
  .carousel-indicators .active {
    background-color: #fff;
  }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  
  .align-baseline {
    vertical-align: baseline !important;
  }
  
  .align-top {
    vertical-align: top !important;
  }
  
  .align-middle {
    vertical-align: middle !important;
  }
  
  .align-bottom {
    vertical-align: bottom !important;
  }
  
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  
  .align-text-top {
    vertical-align: text-top !important;
  }
  
  .bg-primary {
    background-color: #007bff !important;
  }
  
  a.bg-primary:hover, a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: #0062cc !important;
  }
  
  .bg-secondary {
    background-color: #6c757d !important;
  }
  
  a.bg-secondary:hover, a.bg-secondary:focus,
  button.bg-secondary:hover,
  button.bg-secondary:focus {
    background-color: #545b62 !important;
  }
  
  .bg-success {
    background-color: #28a745 !important;
  }
  
  a.bg-success:hover, a.bg-success:focus,
  button.bg-success:hover,
  button.bg-success:focus {
    background-color: #1e7e34 !important;
  }
  
  .bg-info {
    background-color: #17a2b8 !important;
  }
  
  a.bg-info:hover, a.bg-info:focus,
  button.bg-info:hover,
  button.bg-info:focus {
    background-color: #117a8b !important;
  }
  
  .bg-warning {
    background-color: #ffc107 !important;
  }
  
  a.bg-warning:hover, a.bg-warning:focus,
  button.bg-warning:hover,
  button.bg-warning:focus {
    background-color: #d39e00 !important;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
  }
  
  a.bg-danger:hover, a.bg-danger:focus,
  button.bg-danger:hover,
  button.bg-danger:focus {
    background-color: #bd2130 !important;
  }
  
  .bg-light {
    background-color: #f8f9fa !important;
  }
  
  a.bg-light:hover, a.bg-light:focus,
  button.bg-light:hover,
  button.bg-light:focus {
    background-color: #dae0e5 !important;
  }
  
  .bg-dark {
    background-color: #343a40 !important;
  }
  
  a.bg-dark:hover, a.bg-dark:focus,
  button.bg-dark:hover,
  button.bg-dark:focus {
    background-color: #1d2124 !important;
  }
  
  .bg-white {
    background-color: #fff !important;
  }
  
  .bg-transparent {
    background-color: transparent !important;
  }
  
  .border {
    border: 1px solid #dee2e6 !important;
  }
  
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  
  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }
  
  .border-0 {
    border: 0 !important;
  }
  
  .border-top-0 {
    border-top: 0 !important;
  }
  
  .border-right-0 {
    border-right: 0 !important;
  }
  
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  
  .border-left-0 {
    border-left: 0 !important;
  }
  
  .border-primary {
    border-color: #007bff !important;
  }
  
  .border-secondary {
    border-color: #6c757d !important;
  }
  
  .border-success {
    border-color: #28a745 !important;
  }
  
  .border-info {
    border-color: #17a2b8 !important;
  }
  
  .border-warning {
    border-color: #ffc107 !important;
  }
  
  .border-danger {
    border-color: #dc3545 !important;
  }
  
  .border-light {
    border-color: #f8f9fa !important;
  }
  
  .border-dark {
    border-color: #343a40 !important;
  }
  
  .border-white {
    border-color: #fff !important;
  }
  
  .rounded {
    border-radius: 0.25rem !important;
  }
  
  .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  
  .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  
  .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-circle {
    border-radius: 50% !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .d-none {
    display: none !important;
  }
  
  .d-inline {
    display: inline !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-table {
    display: table !important;
  }
  
  .d-table-row {
    display: table-row !important;
  }
  
  .d-table-cell {
    display: table-cell !important;
  }
  
  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  
  .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-print-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  
  .embed-responsive::before {
    display: block;
    content: "";
  }
  
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .embed-responsive-21by9::before {
    padding-top: 42.857143%;
  }
  
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  
  .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  
  .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  
  .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  
  .justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  
  .justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  
  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  
  .justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  
  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  
  .align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  
  .align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  
  .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  
  .align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  
  .align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  
  .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  
  .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  
  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  
  .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  
  .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  
  .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  
  .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  
  .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  
  .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  
  .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  
  .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  
  .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
    .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-sm-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 768px) {
    .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-md-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-md-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-md-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-md-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-md-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-md-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 992px) {
    .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-lg-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 1200px) {
    .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-xl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  .float-left {
    float: left !important;
  }
  
  .float-right {
    float: right !important;
  }
  
  .float-none {
    float: none !important;
  }
  
  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important;
    }
    .float-sm-right {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
  }
  
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important;
    }
    .float-md-right {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
  }
  
  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important;
    }
    .float-lg-right {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
  }
  
  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }
    .float-xl-right {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
  }
  
  .position-static {
    position: static !important;
  }
  
  .position-relative {
    position: relative !important;
  }
  
  .position-absolute {
    position: absolute !important;
  }
  
  .position-fixed {
    position: fixed !important;
  }
  
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
  }
  
  .sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none;
  }
  
  .w-25 {
    width: 25% !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .w-75 {
    width: 75% !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .h-25 {
    height: 25% !important;
  }
  
  .h-50 {
    height: 50% !important;
  }
  
  .h-75 {
    height: 75% !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .mw-100 {
    max-width: 100% !important;
  }
  
  .mh-100 {
    max-height: 100% !important;
  }
  
  .m-0 {
    mEXNin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
    mEXNin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
    mEXNin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
    mEXNin-bottom: 0 !important;
  }
  
  .ml-0,
  .mx-0 {
    mEXNin-left: 0 !important;
  }
  
  .m-1 {
    mEXNin: 0.25rem !important;
  }
  
  .mt-1,
  .my-1 {
    mEXNin-top: 0.25rem !important;
  }
  
  .mr-1,
  .mx-1 {
    mEXNin-right: 0.25rem !important;
  }
  
  .mb-1,
  .my-1 {
    mEXNin-bottom: 0.25rem !important;
  }
  
  .ml-1,
  .mx-1 {
    mEXNin-left: 0.25rem !important;
  }
  
  .m-2 {
    mEXNin: 0.5rem !important;
  }
  
  .mt-2,
  .my-2 {
    mEXNin-top: 0.5rem !important;
  }
  
  .mr-2,
  .mx-2 {
    mEXNin-right: 0.5rem !important;
  }
  
  .mb-2,
  .my-2 {
    mEXNin-bottom: 0.5rem !important;
  }
  
  .ml-2,
  .mx-2 {
    mEXNin-left: 0.5rem !important;
  }
  
  .m-3 {
    mEXNin: 1rem !important;
  }
  
  .mt-3,
  .my-3 {
    mEXNin-top: 1rem !important;
  }
  
  .mr-3,
  .mx-3 {
    mEXNin-right: 1rem !important;
  }
  
  .mb-3,
  .my-3 {
    mEXNin-bottom: 1rem !important;
  }
  
  .ml-3,
  .mx-3 {
    mEXNin-left: 1rem !important;
  }
  
  .m-4 {
    mEXNin: 1.5rem !important;
  }
  
  .mt-4,
  .my-4 {
    mEXNin-top: 1.5rem !important;
  }
  
  .mr-4,
  .mx-4 {
    mEXNin-right: 1.5rem !important;
  }
  
  .mb-4,
  .my-4 {
    mEXNin-bottom: 1.5rem !important;
  }
  
  .ml-4,
  .mx-4 {
    mEXNin-left: 1.5rem !important;
  }
  
  .m-5 {
    mEXNin: 3rem !important;
  }
  
  .mt-5,
  .my-5 {
    mEXNin-top: 3rem !important;
  }
  
  .mr-5,
  .mx-5 {
    mEXNin-right: 3rem !important;
  }
  
  .mb-5,
  .my-5 {
    mEXNin-bottom: 3rem !important;
  }
  
  .ml-5,
  .mx-5 {
    mEXNin-left: 3rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  
  .p-1 {
    padding: 0.25rem !important;
  }
  
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  
  .p-2 {
    padding: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  
  .p-3 {
    padding: 1rem !important;
  }
  
  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }
  
  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }
  
  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  
  .p-4 {
    padding: 1.5rem !important;
  }
  
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }
  
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }
  
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }
  
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }
  
  .p-5 {
    padding: 3rem !important;
  }
  
  .pt-5,
  .py-5 {
    padding-top: 3rem !important;
  }
  
  .pr-5,
  .px-5 {
    padding-right: 3rem !important;
  }
  
  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important;
  }
  
  .pl-5,
  .px-5 {
    padding-left: 3rem !important;
  }
  
  .m-auto {
    mEXNin: auto !important;
  }
  
  .mt-auto,
  .my-auto {
    mEXNin-top: auto !important;
  }
  
  .mr-auto,
  .mx-auto {
    mEXNin-right: auto !important;
  }
  
  .mb-auto,
  .my-auto {
    mEXNin-bottom: auto !important;
  }
  
  .ml-auto,
  .mx-auto {
    mEXNin-left: auto !important;
  }
  
  @media (min-width: 576px) {
    .m-sm-0 {
      mEXNin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
      mEXNin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
      mEXNin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
      mEXNin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
      mEXNin-left: 0 !important;
    }
    .m-sm-1 {
      mEXNin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
      mEXNin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
      mEXNin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
      mEXNin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
      mEXNin-left: 0.25rem !important;
    }
    .m-sm-2 {
      mEXNin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
      mEXNin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
      mEXNin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
      mEXNin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
      mEXNin-left: 0.5rem !important;
    }
    .m-sm-3 {
      mEXNin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
      mEXNin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
      mEXNin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
      mEXNin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
      mEXNin-left: 1rem !important;
    }
    .m-sm-4 {
      mEXNin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
      mEXNin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
      mEXNin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
      mEXNin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
      mEXNin-left: 1.5rem !important;
    }
    .m-sm-5 {
      mEXNin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
      mEXNin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
      mEXNin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
      mEXNin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
      mEXNin-left: 3rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3rem !important;
    }
    .m-sm-auto {
      mEXNin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      mEXNin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      mEXNin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      mEXNin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      mEXNin-left: auto !important;
    }
  }
  
  @media (min-width: 768px) {
    .m-md-0 {
      mEXNin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
      mEXNin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
      mEXNin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
      mEXNin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
      mEXNin-left: 0 !important;
    }
    .m-md-1 {
      mEXNin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
      mEXNin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
      mEXNin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
      mEXNin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
      mEXNin-left: 0.25rem !important;
    }
    .m-md-2 {
      mEXNin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
      mEXNin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
      mEXNin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
      mEXNin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
      mEXNin-left: 0.5rem !important;
    }
    .m-md-3 {
      mEXNin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
      mEXNin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
      mEXNin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
      mEXNin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
      mEXNin-left: 1rem !important;
    }
    .m-md-4 {
      mEXNin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
      mEXNin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
      mEXNin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
      mEXNin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
      mEXNin-left: 1.5rem !important;
    }
    .m-md-5 {
      mEXNin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
      mEXNin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
      mEXNin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
      mEXNin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
      mEXNin-left: 3rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3rem !important;
    }
    .m-md-auto {
      mEXNin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      mEXNin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      mEXNin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      mEXNin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      mEXNin-left: auto !important;
    }
  }
  
  @media (min-width: 992px) {
    .m-lg-0 {
      mEXNin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
      mEXNin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
      mEXNin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
      mEXNin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
      mEXNin-left: 0 !important;
    }
    .m-lg-1 {
      mEXNin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
      mEXNin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
      mEXNin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
      mEXNin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
      mEXNin-left: 0.25rem !important;
    }
    .m-lg-2 {
      mEXNin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
      mEXNin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
      mEXNin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
      mEXNin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
      mEXNin-left: 0.5rem !important;
    }
    .m-lg-3 {
      mEXNin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
      mEXNin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
      mEXNin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
      mEXNin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
      mEXNin-left: 1rem !important;
    }
    .m-lg-4 {
      mEXNin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
      mEXNin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
      mEXNin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
      mEXNin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
      mEXNin-left: 1.5rem !important;
    }
    .m-lg-5 {
      mEXNin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
      mEXNin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
      mEXNin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
      mEXNin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
      mEXNin-left: 3rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3rem !important;
    }
    .m-lg-auto {
      mEXNin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      mEXNin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      mEXNin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      mEXNin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      mEXNin-left: auto !important;
    }
  }
  
  @media (min-width: 1200px) {
    .m-xl-0 {
      mEXNin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
      mEXNin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
      mEXNin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
      mEXNin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
      mEXNin-left: 0 !important;
    }
    .m-xl-1 {
      mEXNin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
      mEXNin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
      mEXNin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
      mEXNin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
      mEXNin-left: 0.25rem !important;
    }
    .m-xl-2 {
      mEXNin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
      mEXNin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
      mEXNin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
      mEXNin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
      mEXNin-left: 0.5rem !important;
    }
    .m-xl-3 {
      mEXNin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
      mEXNin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
      mEXNin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
      mEXNin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
      mEXNin-left: 1rem !important;
    }
    .m-xl-4 {
      mEXNin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
      mEXNin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
      mEXNin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
      mEXNin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
      mEXNin-left: 1.5rem !important;
    }
    .m-xl-5 {
      mEXNin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
      mEXNin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
      mEXNin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
      mEXNin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
      mEXNin-left: 3rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem !important;
    }
    .m-xl-auto {
      mEXNin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      mEXNin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      mEXNin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      mEXNin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      mEXNin-left: auto !important;
    }
  }
  
  .text-justify {
    text-align: justify !important;
  }
  
  .text-nowrap {
    white-space: nowrap !important;
  }
  
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .text-left {
    text-align: left !important;
  }
  
  .text-right {
    text-align: right !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  
  .text-lowercase {
    text-transform: lowercase !important;
  }
  
  .text-uppercase {
    text-transform: uppercase !important;
  }
  
  .text-capitalize {
    text-transform: capitalize !important;
  }
  
  .font-weight-light {
    font-weight: 300 !important;
  }
  
  .font-weight-normal {
    font-weight: 400 !important;
  }
  
  .font-weight-bold {
    font-weight: 700 !important;
  }
  
  .font-italic {
    font-style: italic !important;
  }
  
  .text-white {
    color: #fff !important;
  }
  
  .text-primary {
    color: #007bff !important;
  }
  
  a.text-primary:hover, a.text-primary:focus {
    color: #0062cc !important;
  }
  
  .text-secondary {
    color: #6c757d !important;
  }
  
  a.text-secondary:hover, a.text-secondary:focus {
    color: #545b62 !important;
  }
  
  .text-success {
    color: #28a745 !important;
  }
  
  a.text-success:hover, a.text-success:focus {
    color: #1e7e34 !important;
  }
  
  .text-info {
    color: #17a2b8 !important;
  }
  
  a.text-info:hover, a.text-info:focus {
    color: #117a8b !important;
  }
  
  .text-warning {
    color: #ffc107 !important;
  }
  
  a.text-warning:hover, a.text-warning:focus {
    color: #d39e00 !important;
  }
  
  .text-danger {
    color: #dc3545 !important;
  }
  
  a.text-danger:hover, a.text-danger:focus {
    color: #bd2130 !important;
  }
  
  .text-light {
    color: #f8f9fa !important;
  }
  
  a.text-light:hover, a.text-light:focus {
    color: #dae0e5 !important;
  }
  
  .text-dark {
    color: #343a40 !important;
  }
  
  a.text-dark:hover, a.text-dark:focus {
    color: #1d2124 !important;
  }
  
  .text-muted {
    color: #6c757d !important;
  }
  
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .visible {
    visibility: visible !important;
  }
  
  .invisible {
    visibility: hidden !important;
  }
  
  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
    pre {
      white-space: pre-wrap !important;
    }
    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    tr,
    img {
      page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    .container {
      min-width: 992px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 1px solid #000;
    }
    .table {
      border-collapse: collapse !important;
    }
    .table td,
    .table th {
      background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #ddd !important;
    }
  }
  /*# sourceMappingURL=bootstrap.css.map */




  /* ==========================
   MENU FULLPAGE CSS
========================== */

.panel {
    position: fixed;
    left: -100%;
    /*left or right and the width of your navigation panel*/
    width: 100%;
    /*should match the above value*/
}

.menu-link i {
    text-decoration: none;
    font-size: 40px;
    color: #fff;
}

#menu {
    background: rgba(33, 33, 33, 0.6);
    z-index: 998;
}

#menu ul {
    mEXNin-top: 150px;
}

#menu li {
    text-align: center;
    transition: all 0.4s ease;
    padding: 20px 0;
}

#menu li a {
    color: #fff;
    font-size: 23px;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 20px 0;
}

#menu li a.active {
    color: #edad29;
}

#menu li a:hover {
    color: #edad29;
}

.menu-link {
    z-index: 999;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    mEXNin: 0;
    padding: 0;
    width: 80px;
    height: 80px;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    background-color: transparent;
}

.menu-link:focus {
    outline: none;
}

.menu-link span {
    display: block;
    position: absolute;
    top: 45px;
    left: 18px;
    right: 18px;
    height: 6px;
    border-radius: 2px;
    background: #edad29;
    -webkit-transition: background 0 0.3s;
    transition: background 0 0.3s;
}

.menu-link span::before,
.menu-link span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 76%;
    height: 6px;
    border-radius: 2px;
    background-color: #dc143c;
    content: "";
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    -webkit-transition-delay: 0.3s, 0;
    transition-delay: 0.3s, 0;
}

.menu-link span::before {
    top: -15px;
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, transform;
}

.menu-link span::after {
    bottom: -15px;
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
}

/* active state, i.e. menu open */

.menu-open {
    background-color: transparent;
}

.menu-open span {
    background: none;
}

.menu-open span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.menu-open span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.menu-open span::before,
.menu-open span::after {
    -webkit-transition-delay: 0, 0.5s;
    transition-delay: 0, 0.5s;
}

@media screen and (max-width: 1440px) {
    #menu ul {
        mEXNin-top: 100px;
    }
}

@media screen and (max-width: 991px) {
    #menu li a {
        font-size: 20px;
    }
}

@media screen and (max-width:800px) {
    #menu li {
        padding: 15px 0;
    }
    #menu ul {
        mEXNin-top: 140px;
    }
}

@media screen and (max-width:480px) {
    .menu-link {
        width: 70px;
    }

}

